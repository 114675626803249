.slick-slider {
    display: block !important;
}
.slick-track {
    margin-left: 0;
    display: flex;
    .slick-slide {
        height:auto;
        flex:0 0 auto;
    }
}
.ather-product-slick .slick-track {
    margin-left: auto;
}
// arrow product slider start
.good-arrow-prev,
.good-arrow-next {
    width: 30px;
    height: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    transition: 0.2s linear;
    &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border-left: 2px solid $main_color;
        border-bottom: 2px solid $main_color;
        transform-origin: center;
        transition: .25s linear;
    }
    &.slick-disabled {
        display: none !important;
    }
    &:hover {
        cursor: pointer;
    }
}
.good-arrow-prev {
    left: -10px;
    &::after {
        top: 50%;
        left: 50%;
        transform: rotate(45deg) translate(-50%, -50%);
    }
}
.good-arrow-next {
    right: -30px;
    &::after {
        top: 50%;
        left: 0;
        transform: rotate(225deg) translateY(50%);
    }
}

.ather-product-slick {
    .good-arrow-prev,
    .good-arrow-next {
        top: 100px;
    }
}
// arrow product slider end

// good-gallery start
.good-gallery-wrap {
    position: relative;
}

@media (max-width: 1400px) {
    .good-gallery-wrap {
        padding: 0 20px;
    }
}
// good-gallery end

// blog-slider start
.blog-slider {
    position: relative;
    .slick-track {
        margin-left: 0;
        display: flex;
        .slick-slide {
            height:auto;
            flex:0 0 auto;
        }
    }
    .slick-dots {
        bottom: -15px;
        width: 100%;
        font-size: 0;
        margin: 0 -10px;
        list-style: none;
        text-align: center;
        position: absolute;
        display: flex;
        justify-content: center;
        li {
             width: auto;
             height: auto;
             margin: 0 10px;
             &.slick-active button::after,
             &:hover button::after {
                background: $hover_color;
             }
             button {
                 position: relative;
                 width: 15px;
                 height: 15px;
                 padding: 0;
                 &::before {
                     display: none;
                 }
                 &::after {
                   content: '';
                   position: absolute;
                   left: 0;
                   bottom: 0;
                   width: 100%;
                   height: 3px;
                   background: $main_btn_hover;
                   transition: .2s linear;
               }
             }
        }
    }
}
// blog-slider end

// good page slider start
.product-img-block {
    position: relative;
    .slick-track {
        margin-left: 0;
        display: flex;
        .slick-slide {
            height:auto;
            flex:0 0 auto;
        }
    }
    .slick-dots {
        padding: 20px 15px 30px;
        width: 100%;
        font-size: 0;
        list-style: none;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        li {
             width: auto;
             height: auto;
             margin: 0 10px;
             &.slick-active button::after,
             &:hover button::after {
                background: $hover_color;
                opacity: .75;
             }
             button {
                position: relative;
                width: 45px;
                height: 15px;
                padding: 0;
                &::before {
                    display: none;
                }
                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    background: $disable_color;
                    transition: .2s linear;
                }
             }
        }
    }
}
// good page slider end

// Fonts
@import "fonts";
// Normalize
@import "~normalize.css/normalize.css";
// Variables
@import "variables";
// slick style
@import "~slick-carousel/slick/slick.css";
// default_style
@import "default_style";
// default_classes
@import "default_classes";
// malihu
@import "~malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
// magnific-popup
@import "~magnific-popup/dist/magnific-popup.css";
//selectric
@import "~selectric/src/selectric.scss";
// nouislider
@import '~nouislider/distribute/nouislider.css';
// lightcase
@import "~lightcase/src/css/lightcase.css";
// tooltipster
@import "~tooltipster/dist/css/tooltipster.bundle.min.css";

// header_style
@import "header";
// main_page
@import "main_page";
// main $slider-
@import "main_slider";
//components
@import "components";
// cabinet
@import "cabinet";
// footer-style
@import "footer";
//units_pages
@import "unit_pages";
//good page
@import "good";
//components
@import "slick";
// search
@import "search_page";
// order
@import "order";
// tour
@import "tour";
// additions
@import "additions";
// tooltip
@import "tooltip";

.main {
    flex: 1 0 auto;
    &.padding-top {
        padding-top: 80px;
        @media (max-width: 1024px) {
            padding-top: 60px;
        }
    }
}
.container {
    max-width: 1414px;
    width: 100% !important;
    margin: 0 auto;
    padding: 0 15px;
    .container-max,
    .container {
        padding: 0;
    }
}
.container-max {
    width: 100%;
    padding: 0 15px;
    .container-max,
    .container {
        padding: 0;
    }
}
.container-small {
    max-width: 946px;
    width: 100% !important;
    margin: 0 auto;
    padding: 0 15px;
}
.section-title {
    margin-bottom: 30px;
    font-size: 34px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
}
.section-subtitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 18px;
    @media (max-width: 576px) {
        .section-subtitle {
            margin-bottom: 20px;
            text-align: center;
            font-size: 14px;
        }
    }
}
.section-page {
    padding: 20px 0 100px;
    @media (max-width: 1024px) {
        padding: 20px 0 50px;
    }
    @media (max-width: 576px) {
        padding: 20px 0 30px;
    }
}
.action-title {
    margin-bottom: 35px;
    font-size: 26px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
}
.bg-grey {
    background-color: $main-bg;
}
.bg-white {
    background-color: $white;
}
// btn
.btn-disabled-js {
    cursor: default !important;
}
.visual-main-btn {
    display: inline-block;
    padding: 10px 15px;
    min-width: 202px;
    color: $main_color;
    text-align: center;
    background-color: $white;
    transition: .25s linear;
    &:hover {
        color: $hover_color;
    }
    &.mb {
        margin-bottom: 25px;
        @media (max-width: 1366px) {
            margin-bottom: 15px;
        }
    }
}
.burger-menu-btn-holder.mb {
    margin-bottom: 25px;
    @media (max-width: 1366px) {
        margin-bottom: 15px;
    }
}
.burger-menu-btn {
    display: inline-block;
    padding: 10px 15px;
    min-width: 202px;
    color: $white;
    text-align: center;
    border: 1px solid $blue;
    background-color: $blue;
    transition: .25s linear;
    &:hover {
        color: $blue;
        background-color: transparent;
    }
}

.main-btn {
    display: inline-block;
    padding: 10px 70px;
    color: $white;
    text-align: center;
    border: 1px solid $white;
    transition: .25s linear;
    &:hover {
        border: 1px solid $main_btn_hover;
    }
}

.blue-btn {
    display: inline-block;
    padding: 12px 50px;
    color: $white;
    text-align: center;
    text-transform: uppercase;
    background: $hover_color;
    transition: .25s linear;
    &:hover {
        background: $bg_blue_btn;
        color: $dark_white;
    }
}

.blue-btn-small {
    display: block;
    padding: 10px 10px;
    color: $white;
    font-size: 14px;
    text-align: center;
    background: $hover_color;
    transition: .25s linear;
    &:hover {
        background: $bg_blue_btn;
        color: $dark_white;
    }
}

.grey-btn {
    display: inline-block;
    padding: 12px 50px;
    color: $white;
    text-align: center;
    text-transform: uppercase;
    background: $grey_btn;
    transition: .25s linear;
    &:hover {
        background: $main_btn_hover;
        color: $dark_white;
    }
    &-small {
        padding: 12px 40px;
    }
}

.buy-btn {
    width: calc(40% - 12.5px);
    display: inline-block;
    text-align: center;
    padding: 12px 5px;
    background-color: $hover_color;
    color: $white;
    text-transform: uppercase;
    transition: .25s linear;
    &:hover {
        background: $bg_blue_btn;
        color: $dark_white;
    }
}
.request_manager,
.get_good,
.get_price {
    width: calc(80% - 12.5px);
}
.quick-buy-btn {
    width: calc(40% - 12.5px);
    display: inline-block;
    text-align: center;
    padding: 12px 5px;
    background-color: $white;
    text-transform: uppercase;
    color: $hover_color;
    border: 1px solid $hover_color;
    transition: .25s linear;
    &:hover {
        background-color: $hover_color;
        color: $white;
    }
}
.add-favorite {
    width: 100%;
    height: 100%;
    padding: 12px 5px;
    display: flex;
    background-color: $white;
    border: 1px solid $hover_color;
    transition: .25s linear;
    &:hover {
        background-color: $hover_color;
        .add-favorite-icon svg {
            fill: $white;
        }
    }
    .add-favorite-icon {
        margin: auto;
        width: 26px;
        height: 23px;
        svg {
            fill: $hover_color;
            transition: .25s linear;
        }
    }
}

.add-favorite.in_list {
    background-color: $hover_color;
    &:hover {
        background: $bg_blue_btn;
        .add-favorite-icon svg {
            color: $dark_white;
        }
    }
    .add-favorite-icon svg {
        fill: $white;
    }
}

.page-title {
  font-size: 32px;
  color: $main_color;
  font-family: 'robotoregular';
  font-weight: 400;
  margin-bottom: 15px;
  &--cat {
    text-transform: uppercase;
  }
}
// description start
.description {
    table {
        width: 100%;
    }
    ul {
        margin-bottom: 20px;
        padding-left: 40px;
        list-style: none;
        @media (max-width: 576px) {
            margin-bottom: 15px;
        }
        li {
            position: relative;
            & + li {
                margin-top: 10px;
                @media (max-width: 576px) {
                    margin-top: 5px;
                }
            }
            img {
                margin-top: 20px;
                margin-bottom: 20px;
                @media (max-width: 576px) {
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
            }
            &::before {
                content: '';
                position: absolute;
                left: -16px;
                top: 11px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $main_color;
            }
        }
    }
    ol {
        margin-bottom: 20px;
        padding-left: 40px;
        list-style-type: decimal;
        @media (max-width: 576px) {
            margin-bottom: 15px;
        }
        li {
            & + li {
                margin-top: 10px;
                @media (max-width: 576px) {
                    margin-top: 5px;
                }
            }
            img {
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }
    }
    h2 {
        margin: 0 0 15px 0;
        font-family: $robotoregular;
        font-weight: 400;
        text-transform: uppercase;
    }
    h3 {
        margin: 0 0 15px 0;
        font-family: $robotoregular;
        font-weight: 400;
    }
    p {
        margin-bottom: 20px;
        @media (max-width: 576px) {
            margin-bottom: 15px;
        }
        img {
            display: inline-block;
            max-width: 100%;
            height: auto !important;
        }
    }
}
.desc-post-info {
    padding: 90px 0 110px 0;
    @media (max-width: 1024px) {
        padding: 60px 0 80px 0;
    }
    @media (max-width: 576px) {
        padding: 40px 0 60px 0;
    }
}
//description end

// selectric start
// selecric start
.selectric-wrapper {
    &.selectric-open .selectric .button::after {
        transform: rotate(135deg);
        top: 10px;
    }
    &.selectric-hover .selectric-items,
    &.selectric-open .selectric-items {
        box-shadow: 0 3px 6px rgba(25, 31, 34, 0.15);
    }
    &.selectric-hover .selectric,
    &.selectric-open .selectric {
        border: $input_border;
    }
    .selectric {
        width: 170px;
        border: 1px solid transparent;
        background-color: $white;
        .label {
            padding: 0;
            height: 42px;
            line-height: 42px;
            font-size: 16px;
            text-align: left;
            font-weight: normal;
            color: $main_color;
        }
        .button {
            width: 42px;
            height: 42px;
            line-height: 42px;
            background-color: transparent;

            &::after {
                left: 0%;
                top: -10%;
                display: block;
                width: 10px;
                height: 10px;
                border: none;
                border-top-color: none;
                border-left: 2px solid #191f22;
                border-bottom: 2px solid #191f22;
                transform-origin: center;
                transform: rotate(-45deg);
                transition: .25s linear;
            }
        }
    }
    .selectric-items {
        left: auto;
        right: 0;
        border: none;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        li {
            padding: 10px;
            font-size: 14px;
            color: $main_color;
            text-align: left;
            transition: .2s linear;
            background-color: $white;
            &:not(:first-child) {
                border-top: $input_border;
            }
            &.selected {
                background-color: $main-bg;
            }
            &:hover {
                color: #000;
                background-color: $main-bg;
            }
        }
    }
}

.filters-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background:
    rgba(0, 0, 0, 0.3);
    z-index: 10;
    cursor: pointer;
    &.active {
        bottom: 0;
    }
}

// selectric end
@media (max-width: 1024px) {
    .main {
        padding-top: 60px;
    }
    .section-title {
        font-size: 30px;
    }
}
@media (max-width: 768px) {
    .section-title {
        font-size: 26px;
    }
}
@media (max-width: 576px) {
    .section-title {
        margin-bottom: 20px;
        font-size: 20px;
    }
    .main-btn {
        padding: 5px 35px;
    }
}


#spin {
    display: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(25, 31, 34, 0.6);
    z-index: 999;
    .overlay__inner {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .overlay__content {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .spinner {
        width: 60px;
        height: 60px;
        display: inline-block;
        border-width: 2px;
        border-color: $blue;
        border-top-color: #fff;
        animation: spin 1s infinite linear;
        border-radius: 100%;
        border-style: solid;
    }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

// custom scroll bar start
// scroll bar
.mCSB_scrollTools .mCSB_draggerRail {
    background-color: $main-bg;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: $blue;
    width: 2px;
}
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: $blue;
}
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{
	background-color: $blue;
}
// custom scroll bar end
// radio button start
.radio_label {
    padding-right: 25px;
    position: relative;
    cursor: pointer;
    &::before {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        border: 1px solid #a5a5a5;
        border-radius: 50%;
    }
    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        background-color: $blue;
        border-radius: 100%;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        color: $blue;
        transition: height .05s linear, width .05s linear;
    }
}
.radio_input:checked + .radio_label::after {
    width: 7px;
    height: 7px;
}
// radio button end
.iframe-wrap {
    iframe {
        width: 100% !important;
        @media (max-width: 1024px) {
            height: 300px !important;
        }
    }
}

@media (max-width: 1366px) {
    a[class*='lightcase-icon-'] {
        margin-bottom: 0 !important;
        bottom: 15px !important;
    }
}

#preloader {
    position: fixed;
    z-index: 1001;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    .preloader__img {
        z-index: 22;
        width: 60px;
        height: 60px;
    }
}

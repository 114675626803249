.footer {
    background: url(/images/footer_bg.jpg) center center/cover no-repeat;
    z-index: 1;
    .footer-main {
        padding: 60px 0 25px;
        .footer-holder {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -15px;
        }
        .footer-item-wrap {
            padding: 0 15px;
            // width: 25%;
            flex: 1 0 auto;
        }
        .footer-item-nav__wrap {
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          margin: 0 -15px;
          .footer-item-nav {
            padding: 0 15px;
            & + .footer-item-nav {
              margin-left: 50px;
            }
          }
        }
        .footer-logo {
            display: block;
            margin-bottom: 25px;
            width: 135px;
        }
        .footer-contacts-item {
            margin-bottom: 30px;
            .footer-item-name {
                text-transform: uppercase;
            }
            .footer-nav-item {
                display: flex;
                align-items: center;
            }
            .social-link {
                margin-left: 10px;
            }
        }
        .footer-item-name {
            display: block;
            margin-bottom: 10px;
            font-size: 16px;
            color: $white;
        }
        .footer-item-icon {
            display: none;
        }
        .footer-inner-block {
            margin: 0 -15px;
            display: flex;
            flex-wrap: wrap;
        }
        .footer-nav-wrap {
            margin-bottom: 15px;
            padding: 0 15px;
        }
        .footer-nav-item {
            margin-bottom: 5px;
        }
        .footer-nav-link {
            font-size: 14px;
            color: $grey;
            transition: .2s linear;
            &:hover {
                color: $white;
            }
        }
    }
    .footer-developer {
        border-top: 1px solid $white;
        padding: 10px 0;
        text-align: center;
        .developer-link {
            position: relative;
            color: $grey;
            font-size: 14px;
            transition: .2s linear;
            &:hover {
                color: $white;
            }
        }
    }
}

@media (max-width: 1024px) {
    .footer {
        .footer-main {
            padding: 30px 0 15px;
            .footer-logo {
                margin: 0 auto 40px;
            }
            .footer-item-wrap {
                width: 100%;
            }
            .footer-item-nav__wrap {
              display: block;
              margin: 0;
              .footer-item-nav {
                padding: 0;
                & + .footer-item-nav {
                  margin-left: 0;
                  padding: 0 0 10px;
                }
              }
            }
            .footer-inner-block {
                max-height: none;
                margin: 0;
            }
            .footer-nav-wrap {
                margin-bottom: 12px;
                padding: 0;
                width: 100%;
                border-bottom: $footer_item_border;
            }
            .footer-contacts-item {
                margin-bottom: 12px;
                border-bottom: $footer_item_border;
                // &:first-child {
                //     border-top: $footer_item_border;
                // }
            }
            .footer-item-nav {
                display: none;
                padding-bottom: 10px;
            }
            .footer-item-icon {
                display: block;
                width: 10px;
                height: 10px;
                border-left: 2px solid $white;
                border-bottom: 2px solid $white;
                transform-origin: center;
                transform: rotate(-45deg);
                transition: .25s linear;
            }
            .footer-item-name {
                margin-bottom: 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-transform: uppercase;
                cursor: pointer;
            }
            .footer-item-icon.active {
                transform: rotate(135deg);
            }
        }
    }
}

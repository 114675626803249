.search-page {
    .empty-cart-title {
        padding-top: 50px;
        text-align: center;
    }
    .container--white {
        padding-bottom: 100px;
    }
    .pagination {
        margin-top: 50px;
    }
    .search-info-block {
        margin-bottom: 30px;
        padding: 20px 0 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    .product-wrap {
        display: flex;
        flex-wrap: wrap;
        margin: -20px 0 0 -20px;
    }
    .good-holder {
        width: 25%;
        padding: 20px 0 0 20px;
    }
}
@media (max-width: 1024px) {
    .search-page {
        .container--white {
            padding-bottom: 50px;
        }
        .pagination {
            margin-top: 30px;
        }
        .product-wrap {
            margin: -15px 0 0 -15px;
        }
        .good-holder {
            width: 33.33%;
            padding: 15px 0 0 15px;
        }
    }
}

@media (max-width: 768px) {
    .search-page {
        .good-holder {
            width: 50%;
        }
    }
}

@media (max-width: 576px) {
    .search-page {
        .empty-cart-title {
            padding-top: 30px;
        }
        .container--white {
            padding-bottom: 30px;
        }
        .search-info-block {
            .search-info-count,
            .search-info-sort {
                width: 100%;
                .selectric-wrapper .selectric {
                    width: 100%;
                }
            }
            .search-info-count {
                margin-bottom: 15px;
            }
        }
        .good-holder {
            width: 100%;
        }
    }
}

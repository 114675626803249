// order popup start
.product-order-wrap {
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 30px;
    .empty-cart-title {
        margin-top: 40px;
        text-align: center;
        font-size: 18px;
        color: $blue;
        text-transform: uppercase;
    }
    .product-order-item {
        position: relative;
        padding: 40px 30px 40px 40px;
        border-bottom: $input_border;
        display: flex;
        @media (max-width: 860px) {
            padding: 30px 15px;
        }
        .product-order-img {
            margin-right: 30px;
            max-width: 135px;
            width: 100%;
        }
        .product-order-info {
            width: 100%;
        }
        .product-order-article {
            margin-bottom: 5px;
            text-align: left;
            font-size: 14px;
        }
        .product-order-name {
            display: block;
            margin-bottom: 15px;
            font-family: $robotomedium;
            font-size: 20px;
            color: $main_color;
            text-align: left;
            transition: .25s linear;
            &:hover {
                color: $blue;
            }
        }
        .product-item-gift {
            margin-bottom: 5px;
            display: block;
            text-align: left;
            transition: .25s linear;
        }
        .product-order-count-wrap {
            margin-top: auto;
            display: flex;
            align-items: center;
            @media (max-width: 460px) {
                flex-wrap: wrap;
            }
            .product-order-cost {
                margin-right: 30px;
                text-align: left;
                @media (max-width: 576px) {
                    margin-right: 0;
                    margin-bottom: 10px;
                    width: 100%;
                }
                .old-price {
                    display: inline-block;
                    text-decoration: none;
                    font-size: 14px;
                    color: $bg_mask;
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        width: 100%;
                        height: 2px;
                        background-color: $red;
                    }
                    .currency {
                        font-size: 9px;
                    }
                }
                .actual-price {
                    font-family: $robotomedium;
                    font-size: 18px;
                    .currency {
                        font-size: 14px;
                    }
                }
            }
            .count-input {
                width: 40px;
                height: 40px;
                text-align: center;
            }
        }
        .remove-cart-item {
            margin: 0;
            padding: 12.5px;
            position: absolute;
            top: 30px;
            right: 20px;
            width: 35px;
            height: 35px;
            cursor: pointer;
            @media (max-width: 576px) {
                top: 20px;
                right: 15px;
            }
            &:hover svg {
                fill: $blue;
            }
            svg {
                display: block;
                fill: $main_color;
                transition: .25s linear;
            }
        }
    }
}

.product-order-count {
    border: $input_border;
    display: flex;
    .set-count-minus {
        position: relative;
        display: block;
        height: 40px;
        width: 30px;
        &:hover::before {
            border-top: 1px solid $blue;
        }
        &:before {
            content: '';
            width: 10px;
            height: 1px;
            border-top: 1px solid $main_color;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            transition: .25s linear;
        }
    }
    .set-count-plus {
        position: relative;
        display: block;
        height: 40px;
        width: 30px;
        &:hover::before {
            border-top: 1px solid $blue;
        }
        &:hover::after {
            border-right: 1px solid $blue;
        }
        &::before {
            content: '';
            width: 10px;
            height: 1px;
            border-top: 1px solid $main_color;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            transition: .25s linear;
        }
        &::after {
            content: '';
            width: 1px;
            height: 10px;
            border-right: 1px solid $main_color;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            transition: .25s linear;
        }
    }
}
// order popup end

// ordering-page start
.ordering-block {
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
    .ordering-form-wrap {
        width: 50%;
        padding: 0 15px;
        @media (max-width: 1024px) {
            width: 100%;
        }
    }
    .ordering-block-name {
        margin-bottom: 15px;
        text-transform: uppercase;
        .ordering-name-step {
            margin-right: 13px;
            display: inline-block;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            text-align: center;
            line-height: 25px;
            color: $white;
            background-color: $blue;
        }
    }
    .products-basket-wrap {
        width: 50%;
        padding: 0 15px;
        @media (max-width: 1024px) {
            margin-bottom: 30px;
            width: 100%;
            order: -1;
        }
        .cart-products {
            padding: 0 30px 45px;
            overflow: hidden;
            @media (max-width: 1024px) {
                padding: 0 0 15px;
            }
        }
        .cart-products-title {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            .cart-products-text {
                font-size: 17px;
                text-transform: uppercase;
            }
        }
        .products-name-wrap {
            min-height: 40px;
            border-top: $input_border;
            border-bottom: $input_border;
            display: flex;
            align-items: center;
            font-size: 15px;
            @media (max-width: 576px) {
                display: none;
            }
            .item-product-wrap {
                width: 60%;
                .product-foto {
                    margin-left: 75px;
                    font-size: 13px;
                }
            }
            .item-price-wrap {
                width: 40%;
                display: flex;
                align-items: center;
                .product-item-count,
                .local_sum_container {
                    width: 50%;
                    text-align: center;
                }
            }
        }
        .total-summ-wrap {
            @media (max-width: 1024px) {
                margin-left: auto;
                max-width: 300px;
            }
            @media (max-width: 576px) {
                margin-left: 0;
                max-width: none;
            }
            .total-summ-item-holder {
                padding: 15px 0 0;
                width: 50%;
                @media (max-width: 1024px) {
                    max-width: none;
                    width: 100%;
                }
                .total-summ-item-wrap {
                    margin-bottom: 15px;
                    .total-summ-item {
                        padding: 0;
                        & + .total-summ-item {
                            margin-top: 5px;
                        }
                    }
                }
                .total-summ-item-link {
                    text-decoration: underline;
                    transition: .2s linear;
                    &:hover {
                        color: $blue;
                    }
                }
            }
        }
    }
    .products-name-wrap {
        min-height: 40px;
        border-top: 1px solid #000;
        @media (max-width: 576px) {
            display: none;
        }
    }
    .cart-products-title {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 576px) {
            justify-content: flex-start;
        }
        .cart-products-icon {
            margin-right: 10px;
            display: inline-block;
            svg {
                fill: $main_color;
            }
        }
        .cart-products-text {
            font-size: 17px;
            text-transform: uppercase;
        }
    }
    .cart-product-item {
        padding: 30px 0;
        display: flex;
        align-items: center;
        border-bottom: $input_border;
    }
    .item-product-wrap {
        width: 60%;
        padding: 0;
        display: flex;
        align-items: center;
        @media (max-width: 576px) {
            width: 100%;
        }
    }
    .product-item-article {
        margin-bottom: 5px;
        font-size: 13px;
    }
    .product-item-name {
        font-size: 15px;
        color: $main_color;
        transition: .25s linear;
        &:hover {
            color: $blue;
        }
    }
    .item-price-wrap {
        width: 40%;
        padding: 0;
        display: flex;
        align-items: center;
        @media (max-width: 576px) {
            margin: 0;
            padding-top: 5px;
            width: 100%;
        }
        .product-item-count,
        .local_sum_container,
        .product-item-input {
            margin: 0;
            padding: 0;
            width: 50%;
            text-align: center;
        }
        .local_sum_container {
            @media (max-width: 576px) {
                text-align: right;
            }
        }
        .product-item-input {
            @media (max-width: 576px) {
                text-align: left;
                .product-item-qty {
                    margin-right: auto;
                }
            }
        }
    }
    .product-foto,
    .product-count,
    .product-total-price {
        font-size: 13px;
    }
    .cart-product-item {
        margin: 0;
        padding: 30px 0;
        @media (max-width: 1024px) {
            padding: 15px 0;
            flex-wrap: wrap;
        }
    }
    .product-item-img {
        flex: 0 0 auto;
        margin-right: 20px;
        max-width: 83px;
    }
    .product-item-link {
        display: flex;
        flex-direction: column;
    }
    .product-item-article {
        font-size: 13px;
    }
    .product-item-gift {
        color: $blue;
        transition: .2s linear;
        &:hover {
            color: $blue;
        }
    }
    .product-item-qty,
    .cart-subtotal {
        font-size: 19px;
    }
    .cart-subtotal {
        font-family: $robotomedium;
    }
    .sign {
        font-size: 13px;
        font-family: $robotomedium;
    }

    .comment_order {
        margin-top: 30px;
        @media (max-width: 768px) {
            margin-top: 20px;
            margin-bottom: 0px
        }
        .textarea-form {
            margin-bottom: 0;
            textarea {
                width: 100%;
                min-height: 100px;
                height: 100%;
                padding: 15px 20px;
                border: 1px solid #000;
                &::placeholder {
                    color: $blue;
                }
            }
        }
    }
}

.client-choice-wrap {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: $input_border;
    .client-item {
        flex-grow: 1;
        text-align: center;
        transition: .2s linear;
        &.active,
        &:hover {
            .input-text::after {
                background-color: $blue;
            }
            .input-text {
                color: $blue;
            }
        }
        .input-text {
            position: relative;
            display: block;
            padding: 10px 0;
            cursor: pointer;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -1px;
                width: 100%;
                height: 1px;
                background-color: tranparent;
                transition: .2s linear;
            }
        }
    }
}

.title-input-group {
    margin-top: 10px;
    margin-bottom: 30px;
    @media (max-width: 1024px) {
        margin-bottom: 7px;
    }
    &.city {
        margin-bottom: 15px;
        @media (max-width: 1024px) {
            margin-bottom: 0;
        }
    }
}
.client-block-wrap {
    margin: -30px -15px 45px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1024px) {
        margin: -15px -7.5px 30px;
    }
    .input-wrapper {
        width: calc(50% - 30px);
        height: 50px;
        margin: 25px 15px 0;
        @media (max-width: 1024px) {
            width: calc(50% - 15px);
            margin: 30px 7.5px 0;
        }
        @media (max-width: 576px) {
            width: 100%
        }
    }
}

.radio-group {
    padding: 30px;
    border: $input_border;
    @media (max-width: 768px) {
        padding: 15px;
    }
    .radio-item:not(:last-child) {
        margin-bottom: 10px;
    }
    .radio-label {
        padding-left: 27px;
        position: relative;
        cursor: pointer;
        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            display: inline-block;
            width: 15px;
            height: 15px;
            border: 2px solid $blue;
            border-radius: 50%;
        }
    }
    .radio-input:checked + .radio-label::after {
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        background-color: $blue;
        border-radius: 100%;
        top: 50%;
        transform: translateY(-50%);
        left: 4px;
        color: $blue;
    }
    .radio-input:disabled + .radio-label {
        opacity: .6;
        cursor: not-allowed;
    }
}

.address_variants-wrap {
    margin-bottom: 15px;
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
    .address_variants {
        display: none;
    }
    .textarea-form {
          width: 100%;
          min-height: 120px;
          textarea {
              width: 100%;
              height: 100%;
              padding: 15px 20px;
              border: 1px solid #000;
              &::placeholder {
                  color: #000;
              }
          }
      }
}

.ordering {
    .checkbox .checkbox_label {
        padding-left: 25px;
        position: relative;
        cursor: pointer;
    }

    .checkbox_input + .checkbox_label::before {
        position: absolute;
        top: 2px;
        left: 0;
        content: "";
        width: 15px;
        height: 15px;
        display: inline-block;
        border: 1px solid #a5a5a5;
    }
    .checkbox_input:checked + .checkbox_label::after {
        content: '';
        position: absolute;
        top: 7px;
        left: 5px;
        width: 7px;
        height: 7px;
        background-color: $blue;
    }
    .checkbox.agree-text {
        display: flex;
        flex-wrap: wrap;
    }
    .agree-text {
        margin-bottom: 30px;
        .agree-link {
            color: $blue;
            text-decoration: underline;
        }
    }
    .text-disagree {
        display: none;
    }

    #do_order_btn {
        max-width: 310px;
        width: 100%;
        &.disabled {
            opacity: .7;
            pointer-events: none;
        }
    }
}
.total-summ-wrap {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    .total-summ-item-holder {
        padding: 30px 15px 0;
        width: 33.33%;
        max-width: 310px;
        .total-summ-item-wrap {
            margin-bottom: 20px;
            .total-summ-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 30px;
                & + .total-summ-item {
                    margin-top: 10px;
                }
                .total-summ-item-text,
                .total-summ-item-number {
                    font-size: 15px;
                }
            }
        }
        .total_price {
            display: flex;
            justify-content: space-between;
            align-items: center;
            #cart_all_total {
                font-family: $robotomedium;
                font-size: 19px;
            }
            .buy-btn-sum {
                font-family: $robotomedium;
                font-size: 13px;
            }
        }
    }
}
.product-item-article-mobile {
    font-size: 13px;
    @media (min-width: 576px) {
        display: none;
    }
}
.monobank-summ {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 14px;
    font-family: $robotomedium;
    @media (max-width: 576px) {
        height: auto;
    }
    .monobank-summ-count {
        font-size: 19px;
    }
    .monobank-summ-curr {
        display: none;
        &.active {
            display: inline;
        }
    }
}
// ordering-page end



// add cart side-popup start
.add-product-cart-wrap {
    max-width: 580px;
    margin: 0 0 0 auto;
    .popup-content {
        margin: 0;
        height: 100vh;
        display: flex;
        flex-direction: column;
        background-color: $white;
        @media (max-width: 860px) {
            min-height: 100vh;
            height: 100%;
        }
    }
}

.add-product-cart {
    .popup-content {
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 3px 6px rgba(0,0,0,.16);
    }
    .popup-link.title {
        display: flex;
        align-items: center;
        color: $blue;
        svg {
            margin-right: 20px;
            display: block;
            fill: $blue;
        }
    }
}

.add-product-cart-small-wrap {
    position: relative;
    .add-product-cart-small {
        position: absolute;
        right: 325px;
        width: 350px;
        z-index: -1;
        transition: .2s linear;
        @media (max-width: 860px) {
            display: none;
        }
        &:hover {
            right: 100%;
        }
        .popup-content {
            padding: 25px 0;
            background-color: $white;
        }
        .product-order-wrap {
            overflow-x: hidden;
            overflow-y: auto;
            margin-bottom: 30px;
            .product-order-item {
                position: relative;
                padding: 25px 15px;
                display: flex;
                border-top: $input_border;
                border-bottom: $input_border;
                & + .product-order-item {
                    border-top: none;
                }
                .product-order-img {
                    align-self: flex-start;
                    margin-right: 15px;
                    min-width: 80px;
                    width: 85px;
                }
                .product-order-article {
                    font-size: 11px;
                }
                .product-order-name {
                    margin-bottom: 0;
                    display: block;
                    font-size: 13px;
                    text-align: left;
                    color: $main_color;
                    transition: .2s linear;
                    &:hover {
                        color: $blue;
                    }
                }
                .product-item-gift {
                    margin-bottom: 0;
                    font-size: 13px;
                }
                .product-order-count-wrap {
                    justify-content: flex-start;
                    .product-order-cost {
                        margin-right: 30px;
                        .old-price {
                            font-size: 12px;
                            .currency {
                                font-size: 7px;
                            }
                        }
                        .actual-price {
                            font-size: 15px;
                            .currency {
                                font-size: 11px;
                            }
                        }
                    }
                    .buy-btn-small {
                        position: relative;
                        width: 40px;
                        padding: 5px 10px;
                        svg {
                            fill: $main_color;
                            transition: .2s linear;
                        }
                        &:hover {
                            cursor: pointer;
                            svg {
                                fill: $blue;
                            }
                        }
                        .svg-icon {
                            position: absolute;
                            width: 16px;
                            height: 16px;
                            background: url("/images/plus.svg") center center/cover no-repeat;
                            left: 0;
                            bottom: 9px;
                        }
                    }
                }
            }
        }
    }
}

.footer-side-popup {
    margin-top: auto;
}

.mobile-recomend-goods-wrap {
    padding: 20px 0 0;
    .popup-title {
        padding: 20px 15px 0;
        margin-bottom: 10px;
        font-size: 18px;
    }
    .product-order-item {
        position: relative;
        padding: 7px 15px;
        display: flex;
        border-bottom: $input_border;
        .product-order-img {
            align-self: flex-start;
            margin-right: 15px;
            min-width: 80px;
            width: 85px;
        }
        .product-order-article {
            font-size: 11px;
            text-align: left;
        }
        .product-order-name {
            display: block;
            font-size: 13px;
            text-align: left;
            color: $main_color;
            transition: .2s linear;
            &:hover {
                color: $blue;
            }
        }
        .product-order-count-wrap {
            display: flex;
            justify-content: flex-start;
            .product-order-cost {
                margin-right: 30px;
                text-align: left;
                .old-price {
                    font-size: 12px;
                    color: #a5a5a5;
                    .currency {
                        font-size: 7px;
                    }
                }
                .actual-price {
                    font-size: 15px;
                    .currency {
                        font-size: 11px;
                    }
                }
            }
            .buy-btn-small {
                position: relative;
                width: 40px;
                padding: 5px 10px;
                svg {
                    fill: $main_color;
                    transition: .2s linear;
                }
                &:hover {
                    cursor: pointer;
                    svg {
                        fill: $blue;
                    }
                }
                .svg-icon {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    background: url("/images/plus.svg") center center/cover no-repeat;
                    left: 0;
                    bottom: 9px;
                }
            }
        }
    }
    .ather-product-arrow-prev {
        left: 0;
    }
}
@media (min-width: 860px) {
    .mobile-recomend-goods-wrap {
        padding: 20px 0;
        display: none;
    }
}

.cart-price-block {
    margin-bottom: 25px;
    padding: 0 30px 10px 40px;
    border-bottom: $input_border;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 860px) {
        padding: 0 15px 10px 15px;
    }
    .cart-price-text {
        font-size: 16px;
    }
    .cart-price-sum {
        .cart-price-sum-count {
            font-size: 20px;
            font-family: "robotomedium";
            .sign {
                font-size: 15px;
            }
        }
    }
}

.order-btn-wrap {
    padding: 0 30px 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 860px) {
        padding: 0 15px;
    }
    .continue-btn {
        width: calc(50% - 15px);
        display: inline-block;
        text-align: center;
        padding: 12px 5px;
        color: $main_color;
        text-transform: uppercase;
        transition: 0.25s linear;
        @media (max-width: 576px) {
            width: 100%;
        }
        &:hover {
            color: $blue;
        }
    }
    .open_order_page_js {
        width: calc(50% - 15px);
        @media (max-width: 576px) {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}
// add cart side-popup end

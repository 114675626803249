.tour-list-page {
    .all-tour-list-wrap {
        padding: 40px 0 140px;
        background-color: $white;
        @media (max-width: 1024px) {
            padding: 30px 0 60px;
        }
        @media (max-width: 576px) {
            padding: 15px 0 50px;
        }
    }
    .pagination {
        margin-top: 60px;
    }
}
.action-title {
    margin-bottom: 30px;
}
.tour-list-wrap {
    margin: -20px 0 55px -20px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        margin: -15px 0 55px 0;
    }
    &:last-child {
        margin: -20px 0 0 -20px;
        @media (max-width: 768px) {
            margin: -15px 0 0 0;
        }
    }
}
.tour-list-holder {
    width: 50%;
    padding: 20px 0 0 20px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 15px 15px 0 15px;
    }
}
.tour-list-item-link {
    height: 100%;
    display: block;
    position: relative;
}
.tour-list-img {
    width: 100%;
}
.tour-list-desc {
    position: absolute;
    padding: 20px 20px 20px 40px;
    right: 0;
    bottom: 10px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $addition-visual-bg;
    @media (max-width: 1024px) {
        padding: 20px 20px 20px 20px;
    }
    @media (max-width: 576px) {
        padding: 10px 15px;
    }
    .tour-list-name {
        margin-bottom: 12px;
        color: $white;
        font-size: 30px;
        line-height: 1;
        @media (max-width: 576px) {
            font-size: 20px;
        }
    }
    .tour-list-date {
        margin-bottom: 10px;
        color: $white;
        font-size: 24px;
        line-height: 1;
        @media (max-width: 768px) {
            margin-bottom: 0;
        }
        @media (max-width: 576px) {
            font-size: 16px;
        }
    }
    .main-btn {
        text-transform: uppercase;
        @media (max-width: 768px) {
            display: none;
        }
        &:hover {
            border: 1px solid $white;
        }
    }
}

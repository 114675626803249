* {
    box-sizing: border-box;
    &::focus {
        outline: none;
    }
}
h1,
h2,
h3,
h4 {
    margin: 0;
    padding: 0;
    font-weight: 400;
}
html {
    min-height: 100%;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    &.not-overflow {
        overflow-x: visible;
    }
}
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    font-family: $robotoregular;
    font-size: 16px;
    line-height: 1.4;
    color: $main_color;
    background-color: $main-bg;
    &.overlay {
        overflow: hidden;
    }
}
section {
    padding-top: 55px;
    &.blog-section {
        margin-bottom: 100px;
        @media (max-width: 1024px) {
            margin-bottom: 50px;
        }
    }
}
ol {
    margin-top: 0;
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
img {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    height: auto;
}
a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
}
p {
    margin: 0;
    padding: 0;
}
button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}
button:hover {
    cursor: pointer;
}
textarea {
    resize: none;
}
input,
select,
textarea {
    margin: 0;
    padding: 0;
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-width: 0;
}
input[type=file] {
    width: .1px;
    height: .1px;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

input[type=radio] {
    display: none;
}

input[type="number"] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=checkbox] {
    display: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
:active,
:hover,
:focus {
    outline: 0 !important;
    outline-offset: 0;
}
input:invalid{
    box-shadow:none;
}

// new-main-section start
.new-main-section {
    padding: 0;
    .main-section-img {
        width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        min-height: 550px;
        object-fit: cover;
    }
    .main-slider-item {
        position: relative;
        .content-slide-overlay {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            position: absolute;
            z-index: 1;
        }
    }
    .main-slider-arrow-prev,
    .main-slider-arrow-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
        padding: 5px 15px;
        cursor: pointer;
        @media (max-width: 1440px) {
            padding: 5px;
            width: 40px;
        }
        @media (max-width: 576px) {
            display: none !important;
        }
        svg {
            display: block;
            width: 100%;
            height: 100%;
            fill: $white;
            transition: 0.2s linear;
        }
        &:hover svg {
            fill: $white;
        }
    }
    .main-slider-arrow-prev {
        z-index: 1;
        left: 60px;
        @media (max-width: 1414px) {
          left: 15px;
        }
          @media (max-width: 1024px) {
          left: 0;
        }
    }
    .main-slider-arrow-next {
        z-index: 1;
        right: 60px;
        @media (max-width: 1414px) {
          right: 15px;
        }
        @media (max-width: 1024px) {
          right: 0;
        }
    }
    .main-section-wrap {
        position: relative;
    }
    .main-section-info {
        position: absolute;
        left: 0;
        bottom: 50px;
        z-index: 2;
        @media (max-width: 768px) {
            bottom: 40px;
        }
    }
    .main-section-title {
        margin-bottom: 20px;
        max-width: 673px;
        @media (max-width: 1024px) {
            max-width: 48vw;
        }
        @media (max-width: 768px) {
            max-width: 62vw;
        }
        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .main-section-subtitle {
        margin-bottom: 50px;
        color: $white;
        font-size: 45px;
        text-transform: uppercase;
        @media (max-width: 1024px) {
            margin-bottom: 45px;
            font-size: 34px;
        }
        @media (max-width: 768px) {
            margin-bottom: 30px;
            font-size: 30px;
        }
        @media (max-width: 576px) {
            font-size: 24px;
        }
    }
    .main-section-category-wrap {
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 576px)  {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .main-section-category-link {
        margin-right: 20px;
        position: relative;
        color: $white;
        font-size: 24px;
        text-transform: uppercase;
        transition: 0.2s linear;
        @media (max-width: 1024px) {
            margin-right: 15px;
            font-size: 20px;
        }
        @media (max-width: 576px) {
            font-size: 18px;
        }
        &:hover {
            color: $white;
        }
        & + .main-section-category-link {
            padding-left: 20px;
            @media (max-width: 1024px) {
                padding-left: 15px;
            }
            @media (max-width: 576px) {
                padding-left: 0;
            }
            &::after {
                content: "";
                position: absolute;
                display: block;
                background-color: $white;
                left: -1px;
                top: 0;
                height: 29px;
                width: 1px;
                @media (max-width: 1024px) {
                    height: 25px;
                }
                @media (max-width: 576px) {
                    display: none;
                }
            }
        }
    }
}
// new-main-section end
.first_ecran {
    position: relative;
    padding: 0;
}

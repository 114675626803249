//  BREADCRUMBS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  .breadcrumbs {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex-wrap: wrap;
      background: transparent;
      text-align: center;
      border-radius: 4px;
      padding: 10px 0;
      margin: 0 -10px;
  }
  .crumb-li {
      display: inline-block;
      & + .crumb-li {
        .crumb {
          &::after {
            content: "";
            position: absolute;
            display: block;
            background-color: #191f22;
            left: -1px;
            top: 50%;
            transform: translateY(-50%);
            height: 50%;
            width: 1px;
          }
        }
      }
  }
  .crumb  {
      color: $main_color;
      position: relative;
      display: block;
      padding: 0 10px;
      font-size: 12px;
      transition: .25s linear;
      &:hover,
      &:focus,
      &:active {
          color: $blue;
      }
      &.js-lastcrumb {
        // display: none;
          cursor: default;
          pointer-events: none;
          &::after {
              content: none;
          }
      }
  }

// --------------- buttons -------------------
.btn {
    &--main {
        padding: 15px 50px;
        background-color: $bg_blue;
        color: $white;
        text-transform: uppercase;
        &:hover,
        &:focus,
        &:active {
          background-color: $bg_darkblue;
          color: $white;
        }
    }
    &--small {
        color: $bg_blue;
        padding: 0;
    }
}


// pagination !!!!!!!!!!!!!!!!
.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
  li {
      margin: 0 5px;
      font-size: 18px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      @media (max-width: 576px) {
          padding: 0 4px;
      }
      @media (max-width: 400px) {
          margin: 0 3px;
          padding: 0 3px;
      }
      &.disabled {
          color: $main_color;
      }
      span, a {
        display: flex;
      }
      a {
          color: $main_color;
      }
      &.active {
          border: $hover_border;
      }
      &:last-child span {
          color: $main_color;
      }
  }
}
// pagination !!!!!!!!!!!!!!!!

// FORMS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.input-wrapper {
    padding-bottom: 25px;
    position: relative;
    width: 100%;
    &.no-pad {
        padding-bottom: 0;
    }
}

.input-block {
    border: $input_border;
    background-color: $white;
    color: $main_color;
    position: relative;
    @extend %transition;
    &.with-mg {
        margin-bottom: 10px;
    }
    .selectric-wrapper .selectric {
        border: 1px solid transparent;
        width: 100%;
        .label {
            height: 50px;
            line-height: 50px;
        }
        .button {
            width: 50px;
            height: 50px;
            line-height: 50px;
        }
    }
}

.form-input {
    border: none;
    outline: none;
    background: none;
    background-color: none;
    width: 100%;
    padding: 5px 15px;
    font-size: 16px;
    height: 50px;
    color: $main_color;
    &--textarea {
        height: 100px;
    }
    &:-webkit-autofill {
        -webkit-box-shadow: inset 0 0 0 50px $white !important;
    }
}

.span-placeholder {
    position: absolute;
    top: 9px;
    left: 0;
    color: $main_color;
    font-family: $robotoregular;
    font-size: 16px;
    font-weight: normal;
    @extend %transition;
    pointer-events: none;
    &.required {
        &::after {
            content: '*';
            position: absolute;
            left: 100%;
            top: 0;
            color: $red;
        }
    }
}

.span__error {
    position: absolute;
    color: $red;
    font-size: 10px;
    right: 0;
    top: 100%;
}

label.error {
    position: absolute;
    color: $red;
    font-size: 10px;
    right: 0;
    top: 100% ;
}

.success {
    display: none;
    margin-top: 20px;
    font-family: $robotoregular;
    text-align: center;
}

.radio-label {
    font-family: $robotoregular;
    font-size: 16px;
    line-height: 24px;
    color: $main-color;
    position: relative;
    display: block;
    padding-left: 30px !important;
    &::before {
      content: '';
      -webkit-box-sizing: border-box;
         -moz-box-sizing: border-box;
              box-sizing: border-box;
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid $blue;
      background: $white;
      position: absolute;
      top: 0;
      left: 0;
    }
}

input:checked + .radio-label {
    &::before {
        border: 2px solid $blue;
    }
    &::after {
        content: '';
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
        display: block;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background: $blue;
        position: absolute;
        top: 5px;
        left: 5px;
    }
}

.js_active {
    .radio-label {
        &::before {
            border: 2px solid $blue;
        }
        &::after {
            content: '';
            -webkit-box-sizing: border-box;
               -moz-box-sizing: border-box;
                    box-sizing: border-box;
            display: block;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            background: $blue;
            position: absolute;
            top: 5px;
            left: 5px;
        }
    }
}


.input-hidden {
    display: none;
}


// --------------------------- modals
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@-webkit-keyframes animateright {
  from {
    left: 100%;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@keyframes animateright {
  from {
    left: 100%;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

// side-popup start
.side-popup {
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: none;
    overflow: auto;
    position: fixed;
    background-color: rgba($main_color, 0.6);
    animation-duration: .3s;
    animation-name: animateright;
    &.open {
        left: 0;
        display: block;
    }
    .popup-wrap {
        max-width: 580px;
        width: 100%;
        margin: 0 0 0 auto;
        background-color: $bg_white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        .popup-content {
            margin: 0;
            min-height: 100vh;
            overflow-y: auto;
            @media (max-width: 860px) {
                min-height: 100vh;
                height: 100%;
            }
        }
    }
    .popup-title {
        text-align: left;
        .popup-title-text {
            margin: 0;
            position: relative;
            &::after {
                left: 0;
                width: 110%;
            }
        }
    }
}
.form-thanks {
    display: none;
    margin-top: 15px;
    font-size: 18px;
    text-align: center;
    color: $blue;
    font-family: $robotomedium;
    @media (max-width: 576px) {
        font-size: 16px;
    }
}

.popup {
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: none;
    overflow: auto;
    position: fixed;
    padding-top: 100px;
    background-color: rgba(25, 31, 34, 0.6);
    animation-name: animatetop;
    animation-duration: 0.5s;
    &.open {
        display: block;
    }
    &-center {
        .popup-content {
            margin: 0 auto;
            max-width: 900px;
            background-color: $main-bg;
        }
        .popup-title-block {
            padding: 20px 15px;
            border-bottom: $cab_border;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .popup-title {
            font-family: $robotomedium;
        }
        .popup-content .popup-main {
            padding: 30px 15px;
        }
        .tabs {
            display: flex;
        }
        .tab-link {
            margin-right: 20px;
            cursor: pointer;
        }
    }
  &-close {
     cursor: pointer;
     &:hover svg {
         color: $blue;
     }
     & svg {
         fill: $main_color;
         transition: .25s linear;
     }
  }
  &-content {
    .popup-form {
      display: none;
      &.js_open {
        display: block;
      }
    }
    .popup-main {
        padding: 40px 70px 40px 40px;
        @media (max-width: 576px) {
            padding: 30px 15px;
        }
    }
  }
  &-top {
    padding: 0 30px 0 40px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: $input_border;
  }
  &-link {
    padding: 35px 25px;
    font-size: 18px;
    color: $main_color;
    text-transform: uppercase;
    position: relative;
    transition: .25s linear;
    &.title {
        &:after{
          content: "";
          display: block;
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $blue;
        }
    }
    &.js_open {
      color: $blue;
      &:after{
        content: "";
        display: block;
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $blue;
      }
    }
  }
  &-form {
    padding: 40px 70px 40px 40px;
    text-align: center;
  }
  &-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 5px 0 15px;
      .modal {
          &__btn {
              flex: 1;
              margin-right: 70px;
              max-width: 270px;
          }
          &__link {
              text-decoration: underline;
              color: $main_color;
          }
      }
  }
}

a.popup-link {
    &:hover {
        color: $blue;
    }
}

.social-login-block {
    margin-bottom: 30px;
    .login-butn {
      padding: 15px 20px;
      display: flex;
      align-items: center;
      border: $input_border;
      color: $main_color;
      max-height: 51px;
      transition: .2s linear;
      & + .login-butn {
          margin-top: 20px;
      }
      .login-butn-icon {
          margin-right: 15px;
          display: flex;
      }
  }
}

.form {
  &-block {
    margin-top: 30px;
  }
}

// side-popup end

// add cart side-popup start

.footer-side-popup {
    margin-top: auto;
}


@media (max-width: 425px) {
  .popup {
    &-top {
      padding: 0 15px 0 20px;
    }
    &-link {
      padding: 25px 15px;
    }
    &-form {
      padding: 20px 35px 20px 20px;
    }
    &-close {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .btn {
    &--main {
      padding: 15px;
    }
  }
}

// size tabs start
.table-size-popup {
    padding-top: 80px;
    padding-right: 15px;
    padding-bottom: 30px;
    padding-left: 15px;
    .popup-content {
        background-color: $white;
    }
    .tab-content-size {
        display: none;
    }
    .tabs-container {
        overflow: auto;
        padding: 0;
        border-bottom: $input_border;
        margin-bottom: 15px;
    }
    .tabs-size {
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
    }
    .tab-link-size {
        padding-bottom: 3px;
        font-size: 18px;
        color: $main_color;
        margin-right: 20px;
        transition: .25s linear;
        border-bottom: 3px solid transparent;
        &:hover,
        &.active {
            color: $blue;
            border-bottom: 3px solid $blue;
            cursor: pointer;
        }
    }
    table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
        font-size: 14px;
        border-spacing: 0;
        border-collapse: collapse;
        &>tbody>tr:nth-of-type(odd) {
            background-color: #f9f9f9;
        }
        tbody {
            border-top: 2px solid $table_grey;
        }
    }
    table>thead>tr>th {
        vertical-align: middle;
        text-align: center;
    }
    table>tbody>tr>td, table>tbody>tr>th, table>tfoot>tr>td, table>tfoot>tr>th, table>thead>tr>td, table>thead>tr>th {
        padding: 5px 10px;
        vertical-align: top;
        text-align: center;
        border-top: 1px solid $table_grey;
    }
    table>caption+thead>tr:first-child>td, table>caption+thead>tr:first-child>th, table>colgroup+thead>tr:first-child>td, table>colgroup+thead>tr:first-child>th, table>thead:first-child>tr:first-child>td, table>thead:first-child>tr:first-child>th {
        border-top: 0;
    }
    .table-size-popup-footer{
        text-align: right;
    }
    .tabs-size-close-btn {
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        color: $main_color;
        background-color: $white;
        text-align: center;
        cursor: pointer;
        border: 1px solid $table_grey;
        transition: .2s linear;
        &:hover {
            color: $blue;
            border: 1px solid $blue;
        }
    }
}
// size tabs end

// visual-block start
.visual-section {
    padding-top: 0;
}
.visual-block {
    position: absolute;
    display: flex;
    align-items: flex-end;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    .visual-flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      max-width: 1535px;
      width: 100% !important;
      margin: 0 auto;
      padding: 0 15px;
      margin-bottom: 105px;
      pointer-events: auto;
      @media (min-width: 1921px) {
          max-width: 80vw;
      }
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }
    }
    .visual-item {
      display: flex;
      flex-direction: column-reverse;
      @media (max-width: 1439px) {
        width: 30%;
      }
      @media (max-width: 767px) {
        position: relative;
      }
    }
    .visual-item.active .card-description-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 1;
        transition: .2s linear .1s;
        &.mCS_no_scrollbar{
            .mCustomScrollBox {
                display: flex;
                justify-content: center;
                flex-direction: column;
            }
        }
    }
    .visual-item-title {
        color: $white;
        font-weight: normal;
        font-size: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 455px;
        border: 3px solid $white;
        line-height: 1;
        height: 95px;
        cursor: pointer;
        @media (max-width: 1439px) {
          width: 100%;
        }
        @media (max-width: 1300px) {
          font-size: 27px;
        }
        @media (max-width: 1023px) {
          font-size: 20px;
          height: 70px;
        }
        @media (max-width: 767px) {
          font-size: 30px;
        }
        @media (max-width: 375px) {
          font-size: 25px;
        }
    }
    .card-description-wrap {
        margin-bottom: 50px;
        position: relative;
        display: none;
        &.active {
          display: block;
          z-index: 5;
          .card-description-inner {
            opacity: 1;
          }
        }
        @media (max-width: 767px) {
          margin: 0;
          position: absolute;
          bottom: 85px;
          left: 0;
          width: 100%;
        }
    }

    .card-description-inner {
        width: 100%;
        height: 100%;
        opacity: 0;
        .card-description-inner-wrap {
            padding: 65px 50px 70px;
            width: 455px;
            max-height: 100%;
            max-width: 100%;
            overflow-y: auto;
            background-color: rgba(4, 53, 88, 0.95);
            @media (max-width: 1439px) {
              width: 100%;
            }
            @media (max-width: 1366px) {
                padding: 35px 15px 40px;
            }
            @media (max-width: 576px) {
                padding: 25px 15px 30px;
            }
        }
        .card-description-inner-scroll {
            padding: 0 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

        }
        .description-inner-title {
            margin-bottom: 30px;
            color: $white;
            text-align: center;
            font-size: 24px;
            text-transform: uppercase;
            @media (max-width: 1366px) {
                margin-bottom: 25px;
                font-size: 22px;
            }
        }
        .description-inner-subtitle {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-size: 20px;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
        }
        .description-inner-subtitle-name {
            margin-right: 10px;
        }
        .description-inner-subtitle-icon {
            flex: 0 0 auto;
            width: 10px;
            height: 10px;
            border-left: 2px solid $white;
            border-bottom: 2px solid $white;
            transform-origin: center;
            transform: rotate(-45deg);
            transition: .25s linear;
            &.active {
                transform: rotate(135deg);
            }
        }
        .description-list {
            text-align: center;
            &.accessories-list {
                display: none;
            }
            .description-item {
                &:not(:last-child) {
                    margin-bottom: 25px;
                    @media (max-width: 1366px) {
                        margin-bottom: 15px;
                    }
                }
            }
            // .description-item-link {
            //     color: $white;
            //     transition: .25s linear;
            //     &:hover {
            //         color: $white_hover_menu;
            //     }
            // }
        }
        .description-item-link {
            display: inline-block;
            padding: 10px 15px;
            min-width: 202px;
            text-align: center;
            color: $main_color;
            background-color: $white;
            border: 1px solid $white;
            transition: .25s linear;
            &:hover {
                color: $white;
                background-color: $blue;
            }
            &.mb {
                margin-bottom: 25px;
                @media (max-width: 1366px) {
                    margin-bottom: 15px;
                }
            }
        }
        .main-btn {
            margin-bottom: 1px;
        }
    }
}

@media (max-width: 767px) {
  .visual-block {
    .visual-item + .visual-item {
      margin-top: 20px;
    }
    .visual-item {
      width: 100%;
      max-width: 455px;
    }
  }
}
// @media (max-width: 1024px) {
//     .visual-block {
//         flex-direction: column;
//         .visual-item:first-child .card-description-wrap {
//             padding: 17% 5% 5% 5%;
//         }
//         .visual-item:not(:first-child) .card-description-wrap {
//             padding: 5% 5% 5% 5%;
//         }
//     }
// }

// visual-block end

// moto-accessories start
.moto-accessories {
    .tabs-container {
        padding: 0;
        background-color: $white;
    }
	.tabs {
	    display: flex;
	    flex-wrap: wrap;
        border-bottom: 1px solid $border_grey;
	}
	.tab-link {
        height: 50px;
	    display: flex;
	    justify-content: center;
	    align-items: center;
        max-width: 16.66%;
        width: 100%;
        font-family: $robotomedium;
	    cursor: pointer;
	    transition: .25s linear;
	}
	.tab-link.active {
        color: $white !important;
        background-color: rgba(49, 50, 55, 0.7);
    }
	.tab-link:hover {
	    color: rgba(49, 50, 55, 0.7);
	}
	.tab-content {
        padding-bottom: 20px;
		display: flex;
        flex-wrap: wrap;
	}
    .tab-content-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        max-width: 16.66%;
        width: 100%;
        &:hover .tab-content-name {
            color: $blue;
        }
    }
    .tab-content-img {
        margin-bottom: 5px;
        padding: 15px 15px 0;
        display: flex;
        align-items: flex-end;
    }
    .tab-content-name {
        padding: 0 10px 0;
        flex: 1 0 auto;
        display: flex;
        text-align: center;
        color: $main_color;
        transition: .25s linear;
    }
	/* accordeon */
	.accordion-link {
        padding: 10px 15px;
        display: none;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid $border_grey;
        transition:all 0.2s linear;
        &:first-child {
            border-top: 1px solid $border_grey;
        }
	}

}

@media screen and (max-width: 1024px) {
    .moto-accessories {
        .tabs {
            display: none;
        }
        .accordion-link {
            display: flex;
        }
        .accordion-link.active,
        .accordion-link:hover {
            color: $blue;
        }
        .accordion-icon {
            width: 10px;
            height: 10px;
            border-left: 2px solid $main_color;
            border-bottom: 2px solid $main_color;
            transform-origin: center;
            transform: rotate(-45deg);
            transition: .25s linear;
        }
        .accordion-link.active .accordion-icon {
            transform: rotate(135deg);
        }
        .tab-content-link {
            max-width: 20%;
        }
    }
}
@media screen and (max-width: 768px) {
    .moto-accessories {
        .tab-content-link {
            max-width: 25%;
        }
    }
}
@media screen and (max-width: 576px) {
    .moto-accessories {
        .tab-content-link {
            max-width: 50%;
        }
        .tab-content-name {
            font-size: 14px;
        }
    }
}
// moto-accessories end

// default accessories-wrap start
.accessories-wrap {
    margin: -20px 0 50px -20px;
    display: flex;
    flex-wrap: wrap;
}
.accessories-holder {
    padding: 20px 0 0 20px;
}
.accessories-item {
    display: block;
    position: relative;
    &:hover .accessories-name {
        top: 50%;
        transform: translateY(-50%);
        bottom: auto !important;
    }
}
.accessories-name {
    position: absolute;
    text-align: center;
    left: 15px;
    right: 15px;
    bottom: 20px;
    font-size: 18px;
    text-transform: uppercase;
    color: $white;
    transition: .25s linear;
}
// default accessories-wrap end

// accessories_sections start
.accessories_sections {
    .accessories-holder {
        width: 33.33%;
    }
}
@media (max-width: 768px) {
    .accessories_sections {
        .accessories-holder {
            width: 50%;
            padding: 20px 0 0 20px;
        }
    }
}
@media (max-width: 576px) {
    .accessories_sections {
        .accessories-wrap {
            margin: -15px 0 30px -15px;
        }
        .accessories-holder {
            width: 100%;
            padding: 15px 0 0 15px;
        }
        .accessories-name {
            font-size: 16px;
        }
    }
}
// accessories_sections end
.action-block-wrap {
    margin: -20px 0 0 -20px;
    display: flex;
    flex-wrap: wrap;
    .good-holder {
        width: 16.66%;
    }
}
.cat-page-white-block {
    padding: 40px 0 80px;
    background-color: $white;
    @media (max-width: 1366px) {
        padding: 30px 0 50px;
    }
    @media (max-width: 576px) {
        padding: 20px 0 40px;
    }
}
.main-cat-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: -20px 0 0 -20px;
    .main-cat-holder {
        width: 50%;
        padding: 20px 0 0 20px;
        &:nth-child(odd) .main-cat-description-block {
            text-align: right;
            right: 4.68vw;
        }
        &:nth-child(even) .main-cat-description-block {
            text-align: left;
            left: 4.68vw;
        }
    }
    .main-cat-item {
        height: 100%;
        display: block;
        position: relative;
        .accessories-img {
            width: 100%;
        }
        .main-cat-item {
            width: 100%;
        }
    }
    .accessories-img {

    }
    .main-cat-item-name {
        color: $white;
        font-size: 18px;
        text-transform: uppercase;
        display: none;
    }
    .main-cat-item-desc {
        p {
            font-size: 24px;
            color: $white;
        }
        h3 {
            margin-bottom: 25px;
            font-size: 34px;
            color: $white;
            font-weight: 400;
            text-transform: uppercase;
        }
    }
    .main-cat-description-block {
        position: absolute;
        bottom: 3.125vw;
    }
}

@media screen and (max-width: 768px) {
    .main-cat-wrap {
        margin: -15px 15px 0 15px;
        .main-cat-holder {
            width: 100%;
            padding: 15px 0 0 0;
            &:nth-child(odd) .main-cat-description-block {
                left: 50%;
                bottom: 20px;
                right: auto;
                transform: translateX(-50%);
                transition: .25s linear;
            }
            &:nth-child(even) .main-cat-description-block {
                left: 50%;
                bottom: 20px;
                transform: translateX(-50%);
                transition: .25s linear;
            }
            .main-cat-item:hover .main-cat-description-block {
                bottom: 50%;
            }
        }
        .main-btn {
            display: none;
        }
        .main-cat-item-name {
            display: block;
        }
    }
}
@media screen and (max-width: 576px) {
    .moto-section {
        .main-cat-wrap {
            .main-cat-holder {
                .main-cat-description-block {
                    color: $white;
                    left: 50%;
                    bottom: 50%;
                    transform: translate(-50%, 50%);
                    .main-btn {
                        display: none;
                    }
                }
            }
            .main-cat-item-name {
                font-size: 16px
            }
        }
    }
}
// moto-equipment start
.moto-equipment {

    .accessories-holder {
        width: 33.33%;
        &:nth-child(1),
        &:nth-child(2) {
            width: 50%;
            .accessories-name {
                bottom: 40px;
                font-size: 20px;
                color: $white;
            }
        }
    }
}
@media (max-width: 768px) {
    .moto-equipment {
        .accessories-wrap {
            margin: -15px 0 30px -15px;
        }
        .accessories-holder {
            width: 100%;
            padding: 15px 0 0 15px;
            &:nth-child(1),
            &:nth-child(2) {
                width: 100%;
                .accessories-name {
                    bottom: 20px;
                    font-size: 16px;
                }
            }
        }
    }
}
// moto-equipment end

// main-slide-wrap start
.main-slide-wrap {
    height: 29.5vw;
    overflow: hidden;
    display: block;
    position: relative;
    .slide-image {
    }
    .slide-content-wrap {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4.68vw;
    }
    .slide-content {
        max-width: 320px;
    }
    .slide-name {
        margin-bottom: 25px;
        font-size: 34px;
        text-transform: uppercase;
        text-align: left;
        color: $white;
    }
}
@media (max-width: 1024px) {
    .main-slide-wrap {
        height: 33.25vw;
    }
}
@media (max-width: 768px) {
    .main-slide-wrap {
        height: 41.25vw;
    }
}
@media (max-width: 576px) {
    .main-slide-wrap {
        height: 51.25vw;
        .slide-content-wrap {
            bottom: 15px;
        }
        .slide-content {
            max-width: 175px;
        }
        .slide-name {
            margin-bottom: 0;
            font-size: 16px;
        }
        .main-btn {
            display: none;
        }
    }
}
// main-slide-wrap end

// moto-travel start
@media (max-width: 768px) {
    .moto-travel {
        .main-cat-wrap {
            .main-cat-holder {
                &:nth-child(odd) .main-cat-description-block {
                    text-align: left;
                    left: 20px;
                    transform: translateX(0);
                    right: auto;
                }
                &:nth-child(even) .main-cat-description-block {
                    text-align: left;
                    transform: translateX(0);
                    left: 20px;
                }
            }
            .main-cat-item-desc {
                p {
                    font-size: 24px;
                }
                h3 {
                    margin-bottom: 0;
                    font-size: 34px;
                }
            }
            .main-cat-description-block {
                position: absolute;
                bottom: 20px;
            }
            .main-cat-item:hover .main-cat-description-block {
                bottom: 20px;
            }
        }
    }
}
@media (max-width: 576px) {
    .moto-travel {
        .main-cat-wrap {
            .main-cat-item-desc {
                p {
                    font-size: 16px;
                }
                h3 {
                    margin-bottom: 0;
                    font-size: 20px;
                }
            }
        }
    }
}
// moto-travel end

.addition {
    position: relative;
    background-color: $white;
}
.addition-inner-wrap {
    padding: 0 0 90px;
    @media (max-width: 1024px) {
        padding: 0 0 60px;
    }
    @media (max-width: 576px) {
        padding: 0 0 30px;
    }
}
.additions_title {
    padding-bottom: 20px;
    text-align: center;
    font-size: 34px;
    font-family: $robotomedium;
    @media (max-width: 1366px) {
        font-size: 28px;
    }
    @media (max-width: 576px) {
        padding-bottom: 10px;
    }
}
.additions_subtitle {
    margin-bottom: 50px;
    text-align: center;
    font-size: 20px;
    font-family: $robotomedium;
    @media (max-width: 1366px) {
        font-size: 18px;
        margin-bottom: 30px;
    }
}
.addition_link {
    position: absolute;
    top: 50px;
    right: 50px;
    display: block;
    width: 10px;
    height: 10px;
    z-index: 100;
    svg {
        fill: #1d1d1d;
        display: block;
        width: 100%;
        height: 100%;
    }
}
.additions_flex_container {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 -15px;
    .additions_child {
        padding: 0 15px;
        @media (max-width: 1024px) {
            min-width: 100%;
        }
        & + .additions_child {
            @media (max-width: 1024px) {
                margin-top: 30px;
            }
        }
    }
    .addition_multicol_content {
        height: 100%;
    }
    .additions_inner {
        height: 100%;
    }
    .additions_block {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: stretch;
    }
}

.addition-btn-wrap {
    display: flex;
	margin: 30px -7.5px 0;
    @media (max-width: 576px) {
        flex-wrap: wrap;
    }
    .addition-btn-white,
    .addition-btn-blue {
		margin: 0 7.5px;
		max-width: calc(50% - 15px);
		width: 100%;
        @media (max-width: 576px) {
            max-width: 100%;
        }
	}
    .addition-btn-blue {
        display: inline-block;
        text-align: center;
        padding: 12px 5px;
        background-color: $hover_color;
        color: $white;
        text-transform: uppercase;
        transition: .25s linear;
        &:hover {
            background: $bg_blue_btn;
            color: $dark_white;
        }
    }
    .addition-btn-white {
        display: inline-block;
        text-align: center;
        padding: 12px 5px;
        background-color: $white;
        text-transform: uppercase;
        color: $hover_color;
        border: 1px solid $hover_color;
        transition: .25s linear;
        @media (max-width: 576px) {
            margin-top: 15px;
        }
        &:hover {
            background-color: $hover_color;
            color: $white;
        }
    }
}

.addition-visual-section {
    position: relative;
    .additions-breadcrumbs-wrap {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 3;
        .breadcrumbs {
            padding-top: 15px;
            .crumb {
              color: $white;
            }
            .crumb-li + .crumb-li .crumb::after {
                  background-color: #fff;
            }
        }
    }
    .addition-visual-content-wrap {
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
        @media (max-width: 1200px) {
            bottom: 30px;
        }
        @media (max-width: 1024px) {
            position: static;
        }
    }
    .addition-visual-content {
        position: relative;
        max-width: 650px;
        padding: 50px 60px 60px 0;
        color: $white;
        z-index: 2;
        @media (max-width: 1366px) {
            padding: 30px 40px 30px 0;
        }
        @media (max-width: 1024px) {
            padding: 30px 15px 40px 0;
            color: $main_color;
        }
        @media (max-width: 576px) {
            padding: 20px 0 30px 0;
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: -500px;
            background-color: $addition-visual-bg;
            z-index: -1;
            @media (max-width: 1024px) {
                display: none;
            }
        }
        .addition-visual-title {
            margin-bottom: 20px;
            font-size: 34px;
            @media (max-width: 1366px) {
                font-size: 28px;
            }
        }
        .addition-visual-subtitle {
            margin-bottom: 35px;
            font-size: 24px;
            @media (max-width: 1366px) {
                margin-bottom: 25px;
                font-size: 18px;
            }
        }
        .addition-main-btn {
            display: inline-block;
            padding: 10px 60px;
            color: $white;
            text-align: center;
            text-transform: uppercase;
            border: 1px solid $white;
            transition: .25s linear;
            @media (max-width: 1024px) {
                background-color: $hover_color;
                color: $white;
            }
            @media (max-width: 576px) {
                width: 100%;
            }
            &:hover {
                border: 1px solid $white;
                @media (max-width: 1024px) {
                    background: $bg_blue_btn;
                    color: $dark_white;
                }
            }
        }
    }
}
.addition-short-data {
    background-color: $white;
    .addition-short-data-wrap {
        margin-bottom: 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media (max-width: 1024px) {
            margin-bottom: 40px;
        }
        @media (max-width: 576px) {
            margin-bottom: 30px;
        }
        .addition-short-data-item {
            margin: 0 70px;
            padding-top: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media (max-width: 576px) {
                margin: 0 30px;
            }
            .addition-short-data-icon {
                width: 40px;
                height: 40px;
                margin-bottom: 10px;
                svg {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }
            .addition-short-data-text {
                font-size: 18px;
            }
        }
    }
}

// addition iframe start
.addition-iframe-wrap {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-width: 0;
      outline-width: 0;
  }
}
// addition iframe end
// addition video start
.addition-video {
    width: 100%;
     height: auto;
     max-height: 100%;
}
// addition video end
.additions-gallary-wrap {
    background-color: $white;
    padding: 0 0 90px;
    @media (max-width: 1024px) {
        padding: 0 0 60px;
    }
    @media (max-width: 576px) {
        padding: 0 0 30px;
    }
    .additions_title {
        padding-bottom: 30px;
        @media (max-width: 1024px) {
            padding-bottom: 20px;
        }
        @media (max-width: 576px) {
            padding-bottom: 10px;
        }
    }
}
.additions-gallary-img {
    position: relative;
    .arrow {
        padding: 20px;
        width: 50px;
        height: 70px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: $addition-visual-bg;
        cursor: pointer;
        @media (max-width: 768px) {
            padding: 10px;
            width: 30px;
            height: 40px;
        }
        svg {
            display: block;
            width: 100%;
            height: 100%;
            fill: $white;
        }
        &.slick-disabled {
            display: none !important;
        }
    }
    .additions-prev-arrow-slider {
        left: 0;
        z-index: 2;
    }
    .additions-next-arrow-slider {
        right: 0;
        z-index: 2;
    }
    .slick-slide {
        margin: 0 10px;
    }
    .slick-list {
        margin: 0 -10px;
    }
}

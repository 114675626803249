//good item start
.good-holder {
    padding: 20px 0 0 20px;
}
.good-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    transition: .25s linear;
    &:hover .good-name {
        color: $hover_color;
    }
}
.good-img-block {
    position: relative;
    margin-bottom: 10px;
}
.status-item-wrap {
    position: absolute;
    top: 10px;
    left: 0;
    display: flex;
    flex-direction: column;
}
.status-item {
    margin-bottom: 5px;
    padding: 2.5px 5px;
    min-width: 70px;
    font-size: 12px;
    text-align: center;
    color: $white;
    &.status-top {
        background-color: rgba(205, 169, 117, 0.85);
    }
    &.status-new {
        background-color: rgba(136, 143, 156, 0.85);
    }
    &.status-stock {
        background-color: rgba(151, 25, 48, 0.85);
    }
    &.status-remains {
        background-color: rgba(130, 177, 83, 0.85);
    }
}
.good-name-block {
    height: 100%;
    padding: 0 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $main_color;
    overflow: hidden;
}
.good-name {
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
    transition: .25s linear;
}
.product-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $robotomedium;
    font-size: 18px;
    &.center {
        justify-content: center;
    }
    .actual-price.request {
        color: $bg_mask;
        font-size: 16px;
    }
}
.ather-product-slick .product-price {
    font-size: 16px;
}
.side-popup .product-price {
    flex-direction: column;
    align-items: flex-start;
}
.old-price {
    color: $red;
    text-decoration: line-through;
}
//good item end
//goods_list start
.catalogue-list-block {
    padding-top: 20px;
    padding-bottom: 90px;
    background-color: $white;
}
.catalogue-list-wrap {
    display: flex;
    .catalogue-list-filters {
        position: relative;
        width: 25%;
    }
    .catalogue-list-products {
        width: 75%;
        padding-top: 20px;
        border-top: $unit_block_border;
    }
    .pagination {
        margin-top: 60px;
    }
}
@media (max-width: 1024px) {
    .catalogue-list-block {
        padding-top: 20px;
        padding-bottom: 60px;
    }
    .catalogue-list-wrap {
        .catalogue-list-filters {
            width: 100%;
            background-color: $white;
            max-width: 320px;
            position: fixed;
            top: 0;
            bottom: 0;
            left: -100%;
            z-index: 17;
            overflow: auto;
            padding: 0 15px;
            display: block;
            transition: .25s;
            &.active {
                left: 0;
            }
        }
        .catalogue-list-products {
            width: 100%;
        }
        .pagination {
            margin-top: 60px;
        }
    }
}
@media (max-width: 576px) {
    .catalogue-list-block {
        padding-bottom: 40px;
    }
}
// сhoice-motorcycle start
.сhoice-motorcycle-popup-wrap {
    position: relative;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1414px;
    .popup-inner-header {
        padding: 15px 65px 15px 15px;
        text-align: center;
        font-family: $robotomedium;
        text-transform: uppercase;
        color: $hover_color;
        background-color: $white;
        border-bottom: $unit_block_border;
        @media (max-width: 576px) {
            font-size: 15px;
        }
    }
    .mfp-close {
        position: absolute;
        top: 5px;
        right: 20px;
    }
}
.сhoice-motorcycle-block {
    margin-bottom: 20px;
    padding: 20px 35px 25px;
    background-color: $disable_color;
    .сhoice-motorcycle-block-inner {
        text-align: center;
        margin-bottom: 10px;
    }
    .сhoice-motorcycle-block-icon {
        text-align: center;
    }
    .сhoice-motorcycle-img {
        display: inline-block;
        position: relative;
        margin-bottom: 5px;
        .сhoice-motorcycle-remove {
            padding: 10px;
            position: absolute;
            top: -10px;
            right: -25px;
            svg {
                display: block;
            }
        }
    }
    .сhoice-motorcycle-name {
        font-size: 14px;
        text-align: center;
    }
}
//filters block start
.filter-block {
    padding-right: 30px;
    position: sticky;
    top: 120px;
    height: calc(100vh - 120px);
    z-index: 2;
    overflow-y: auto;
    overflow-x: hidden;
}
.filter-close-wrap {
    padding: 20px 0;
    display: none;
    @media (max-width: 1024px) {
        display: block;
    }
    .filter-close {
        cursor: pointer;
        text-transform: uppercase;
        transition: .2s linear;
        &:hover {
            color: $blue;
        }
        &:hover::before {
            left: 0;
        }
        padding-left: 30px;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 13px;
            height: 9px;
            background: url(/images/back-arrow.svg) center center/cover no-repeat;
            transition: .2s linear;
        }
    }
}
.filters-item {
    padding: 0 20px;
    border-bottom: 1px solid $disable_color;
    &.categories-filters-item {
        background-color: $disable_color;
    }
    &.filter-close-wrap {
        padding: 20px;
    }
    &.filters__active {
        display: none;
        @media (max-width: 1024px) {
            display: block;
        }
    }
}
.filters-item-title {
    padding: 20px 10px 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.filters-item-name {
    padding-right: 10px;
    font-family: $robotomedium;
    text-transform: uppercase;
}
.filters-item-icon {
    display: flex;
    flex: 0 0 auto;
    width: 10px;
    height: 10px;
    border-left: 2px solid $main_color;
    border-bottom: 2px solid $main_color;
    transform-origin: center;
    transform: rotate(135deg);
    transition: .25s linear;
    &.active {
        margin-top: 5px;
        transform: rotate(-45deg);
    }
}
.filters-item-subtitle {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    cursor: pointer;
    .filters-item-subtitle-name {
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .filters-item-subtitle-icon {
        display: flex;
        flex: 0 0 auto;
        width: 8px;
        height: 8px;
        border-left: 2px solid $main_color;
        border-bottom: 2px solid $main_color;
        transform-origin: center;
        transform: rotate(-45deg);
        transition: .25s linear;
        &.active {
            transform: rotate(135deg);
        }
    }
}
.filters-item-subtitle-info {
    display: none;
    padding-bottom: 15px;
    .filter-item {
        padding-left: 15px;
    }
}
.filters-item-info {
    padding: 0 10px 20px 0;
    .filters__active-list {
        margin-bottom: 15px;
        li {
            margin-bottom: 10px;
        }
        .filter-item {
            margin-bottom: 20px;
            padding-left: 10px;
            font-size: 14px;
            &:hover .active-filter-item-close svg {
                fill: $hover_color;
            }
        }
        .active-filters-item-name {
            margin-bottom: 10px;
            font-size: 15px;
            text-transform: uppercase;
        }
        .active-filter-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .active-filter-item-close {
            margin-left: 10px;
            svg {
                fill: $main_color;
            }
        }
    }
    .blue-btn {
        width: 100%;
    }
    .filter-item {
        display: block;
        color: $main_color;
        font-size: 15px;
        transition: .25s linear;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &:hover {
            color: $bg_mask;
        }
        &.active {
            position: relative;
            padding-left: 23px;
            color: $bg_mask;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 13px;
                height: 12px;
                background: url(/images/tick.png) center center/cover no-repeat;
            }
        }
    }
    .checkbox-item {
        margin-bottom: 10px;
    }
    .checkbox_label {
        display: inline-block;
        position: relative;
        cursor: pointer;
        transition: .25s linear;
        font-size: 15px;
        font-weight: normal;
        &:hover {
            color: $date_color;
        }
    }
    .checkbox_input:checked + .checkbox_label {
        padding-left: 23px;
    }
    .checkbox_input:checked + .checkbox_label::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 13px;
        height: 12px;
        background: url(/images/tick.png) center center/cover no-repeat;
    }
    .filters-item {
        margin-right: -10px;
        border-bottom: none;
        &:last-child {
            .filters-item-info {
                padding: 0 0 10px 0;
            }
        }
        .filters-item-title {
            padding: 0 10px 20px 0;
            .filters-item-name {
                font-size: 15px;
            }
        }
        .filters-item-info {
            padding: 0 0 20px 10px;
            .checkbox_label {
                font-size: 14px;
            }
        }
    }
}
@media (max-width: 1024px) {
    .filter-block {
        padding-right: 0;
        position: relative;
        top: 0;
        width: 100%;
        height: auto;
    }
}
// slider-range start
.slider-range-cover {
    padding: 0 15px;
    margin-bottom: 30px;
    .noUi-horizontal {
        height: 5px;
    }
    .noUi-connect {
        background: $blue_rgba;
    }
    .noUi-horizontal {
        border: none;
        box-shadow: none;
    }
    .noUi-tooltip {
        display: none;
    }
    .noUi-target {
        background: #ebebeb;
    }
    .noUi-horizontal .noUi-handle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid $blue_rgba;
        cursor: pointer;
        box-shadow: none;
        right: -10px;
    }
    .noUi-handle:before,
    .noUi-handle:after {
        display: none;
    }
}
.range-price-wrap {
    margin-bottom: 15px;
    display: flex;
    .range-price-input {
        width: 50%;
        &:first-child .input-block .price-input {
            border-right: none;
        }
        .price-input {
            text-align: center;
            height: 35px;
            line-height: 1;
            width: 100%;
            border: $input_border;
            &::placeholder {
               color: $main_color;
           }
        }
    }
}
// slider-range end

//filters block end
.catalogue-list-products-wrap {
    margin: -20px 0 0 -20px;
    display: flex;
    flex-wrap: wrap;
    &.only-moto {
        margin: -5px 0 0 -20px;
        @media (max-width: 576px) {
            margin: -10px 0 0 -20px;
        }
    }
    .good-holder {
        width: 33.33%;
    }
    .your-moto-block {
        margin-top: 20px;
        padding-left: 20px;
        padding-bottom: 15px;
        border-bottom: $unit_block_border;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .your-moto-img {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        .your-moto-wrap {
            margin-left: 30px;
        }
        .your-moto {
            margin-bottom: 5px;
        }
        .your-moto-name {
            font-size: 20px;
            text-transform: uppercase;
        }
        .reset_model {
            color: $main_color;
            transition: .25s linear;
            text-decoration: underline;
            &:hover {
                color: $blue;
            }
        }
    }
    .section-subtitle {
        margin-top: 50px;
        padding-left: 20px;
        text-align: left;
        color: $blue;
        text-transform: uppercase;
        width: 100%;
    }
    .page__info-label {
        width: 100%;
        padding-left: 20px;
        margin-top: 50px;
        text-align: center;
        .filters__reset {
            color: $main_color;
            text-decoration: underline;
            transition: .25s linear;
            &:hover {
                color: $blue;
            }
        }
    }
}
.sort-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .sort-block-filters-wrap {
        margin-left: auto;
        width: 75%;
        display: flex;
        align-items: flex-start;
        .sort-block-active-filters {
            display: flex;
            align-items: flex-end;
        }
        .sort-block-active-list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            @media (max-width: 1024px) {
                display: none;
            }
        }
        .sort-block-active-item {
            padding: 5px 0;
            margin-right: 20px;
            display: flex;
            font-size: 14px;
            color: $main_color;
            transition: .25s linear;
            &:hover {
                color: $hover_color;
            }
            &:hover .active-filter-item-close svg {
                fill: $hover_color;
            }
        }
        .active-filter-item-value {
            white-space: nowrap;
        }
        .active-filter-item-close {
            margin-left: 5px;
            svg {
                fill: $main_color;
                transition: .25s linear;
            }
        }
        .sort-block-active-reset {
            padding: 5px 0;
            font-size: 14px;
            color: $main_color;
            text-decoration: underline;
            transition: .25s linear;
            &:hover {
                color: $hover_color;
            }
        }
    }
    .sort-form {
        flex: 0 0 auto;
        margin-left: auto;
    }
    .catalogue__filter-mob {
        margin: 0;
    }
}
.catalogue__filter-mob {
    margin: 15px 0 0 20px;
    display: none;
}
.goods-category-wrap {
    margin: -20px 0 50px -20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 1024px) {
        margin: -20px 0 40px -20px;
    }
    @media (max-width: 768px) {
        margin: -20px 0 30px -20px;
    }
    .goods-category-holder {
        width: 16.66%;
        padding: 20px 0 0 20px;
        @media (max-width: 1024px) {
            width: 20%;
        }
        @media (max-width: 768px) {
            width: 33.33%;
        }
        @media (max-width: 576px) {
            width: 50%;
        }
    }
    .goods-category-item {
        &:hover .goods-category-name {
            color: $hover_color;
        }
    }
    .goods-category-img {
        margin-bottom: 5px;
    }
    .goods-category-name {
        font-size: 14px;
        text-align: center;
        color: $main_color;
        transition: .25s linear;
    }
}
@media (max-width: 1024px) {
    .catalogue__filter-mob {
        display: block;
    }
}
@media (max-width: 768px) {
    .catalogue-list-products-wrap {
        .good-holder {
            width: 50%;
        }
    }
}
@media (max-width: 576px) {
    .catalogue-list-products-wrap {
        .sort-block {
            .sort-form {
                width: 100%;
                .selectric-wrapper .selectric {
                    width: 100%;
                }
            }
        }
        .search-info-count {
            margin-bottom: 15px;
        }
        .good-holder {
            width: 100%;
        }
        .your-moto-block {
            .your-moto-wrap {
                margin: 15px 0 0 0;
                width: 100%;
            }
            .your-moto-img {
                max-width: 600px;
                margin-right: 0;
                margin-bottom: 15px;
            }
            .reset_model {
                color: $main_color;
                transition: .25s linear;
                text-decoration: underline;
                &:hover {
                    color: $blue;
                }
            }
        }
    }
    .catalogue__filter-mob {
        width: 100%;
    }
}
//goods_list end

// order page start
.good_page {
    background-color: $white;
    @media (max-width: 768px) {
        .ather-product {
            position: relative;
        }
    }
    .product-info-wrap {
        padding-bottom: 115px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: $white;
        @media (max-width: 1024px) {
            padding-bottom: 60px;
        }
        @media (max-width: 768px) {
            padding-bottom: 0;
        }
    }
    &.controller_moto,
    &.controller_accessories {
        .product-img-block-wrap {
            padding-top: 0;
        }
    }
    .product-img-block-wrap {
        position: relative;
        width: calc(50% - 10px);
        padding-top: 60px;
        @media (max-width: 1024px) {
            padding-top: 40px;
            // padding-bottom: 50px;
        }
        @media (max-width: 768px) {
            position: fixed;
            top: 61px;
            left: 15px;
            right: 15px;
            width: calc(100% - 30px);
            padding-top: 0;
        }
        .unit-nav {
            margin-top: 5px;
            margin-bottom: 0;
            display: none;
            @media (max-width: 768px) {
                display: block;
            }
        }
        .product-img-block {
            height: 100%;
            @media (max-width: 768px) {
                height: auto;
            }
        }
        .status-item-wrap {
            top: 40px;
            left: auto;
            right: 0;
            .status-item {
                margin-bottom: 10px;
                padding: 5px 35px;
                min-width: 70px;
                font-size: 16px;
                text-align: center;
                color: $white;
            }
        }
        .good-lightcase {
            display: block;
        }
        .good-lightcase + .good-lightcase {
            margin-top: 30px;
            @media (max-width: 1024px) {
                margin-top: 0;
            }
        }
        .share-block {
            display: none;
            @media (max-width: 1024px) {
                position: absolute;
                right: 0;
                bottom: 0;
                display: flex;
                flex-direction: column;
                .share-socials-wrap {
                    margin: -5px 0;
                    flex-direction: column;
                    .share-socials-item {
                        margin: 5px 0;
                        .share-socials-link svg {
                            fill: $blue;
                        }
                    }
                }
                .share-toggle-btn {
                    margin-top: 10px;
                    margin-left: 0;
                }
            }
            @media (max-width: 768px) {
                bottom: 11px;
            }
        }
    }
    .product-info-block {
        position: relative;
        width: calc(50% - 10px);
        padding-left: 60px;
        z-index: 2;
        @media (max-width: 1200px) {
            padding-left: 30px;
        }
        @media (max-width: 1024px) {
            padding-bottom: 40px;
        }
        @media (max-width: 768px) {
            width: 100%;
            padding: 60px 0;
        }
        @media (max-width: 576px) {
            padding: 35px 0;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 2000px;
            z-index: -1;
            background-color: $disable_color;
            @media (max-width: 768px) {
                left: -15px;
            }
        }
        .product-info-block-wrap {
            position: sticky;
            top: 95px;
            bottom: 0;
            .share-block {
                padding-bottom: 115px;
            }
            @media (max-width: 1024px) {
                .share-block {
                    display: none;
                }
            }
        }
    }
    .product-collection {
        margin-bottom: 10px;
        font-size: 20px;
        @media (max-width: 1024px) {
            margin-bottom: 5px;
            font-size: 16px;
        }
    }
    .product-name {
        margin-bottom: 30px;
        line-height: 1.2;
        font-size: 36px;
        font-family: $robotomedium;
        @media (max-width: 1024px) {
            margin-bottom: 20px;
            font-size: 24px;
        }
    }
    .product-price-block {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .actual-price,
        .old-price {
            margin-right: 25px;
            font-family: $robotomedium;
            font-size: 30px;
            text-decoration: none;
            @media (max-width: 1024px) {
                font-size: 24px;
            }
            .currency {
                font-size: 22px;
                @media (max-width: 1024px) {
                    font-size: 20px;
                }
            }
        }
        .actual-price {
            color: $black;
        }
        .old-price  {
            color: $bg_mask;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 100%;
                height: 3px;
                background-color: $red;
            }
        }
        .monobank-btn {
            width: 50px;
            height: 50px;
            @media (max-width: 1024px) {
                width: 40px;
                height: 40px;
            }
        }
    }
    .choice-details {
        margin: 0 -12.5px 50px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        @media (max-width: 1024px) {
            margin: 0 -12.5px 30px;
        }
        @media (max-width: 768px) {
            margin: 0 -12.5px 40px;
        }
    }
    .choice-details-item {
        width: 50%;
        padding: 0 12.5px;
        @media (max-width: 1024px) {
            width: 100%;
        }
        @media (max-width: 768px) {
            width: 50%;
        }
        @media (max-width: 576px) {
            width: 100%;
        }
        & + .choice-details-item {
            @media (max-width: 1024px) {
                margin-top: 20px;
            }
            @media (max-width: 768px) {
                margin-top: 0;
            }
            @media (max-width: 576px) {
                margin-top: 20px;
            }
        }
        .sort_item-btn {
            width: 100%;
            border: $input_border;
            font-size: 16px;
            padding: 13px 15px;
            cursor: pointer;
            color: $black_opacity;
            position: relative;
            background-color: $white;
            transition: .25s linear;
            @media (max-width: 576px) {
                padding: 10px 15px;
            }
            .sort_item-btn-text {
                text-transform: lowercase;
                &::first-letter {
                    text-transform: uppercase;
                }
            }
            &.selected {
                color: $black;
            }
            &.red_border {
                border-color: $red;
            }
            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 8px;
                height: 8px;
                border-top: 1px solid $black;
                border-right: 1px solid $black;
                right: 10px;
                top: 50%;
                transform: translateY(-50%) rotate(135deg);
            }
            &.active::after {
                transform: translateY(-25%) rotate(-45deg);
            }
        }
        .sort_item-wrap {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 5;
            box-shadow: 0 3px 6px rgba(25,31,34,.15);
            display: none;
            width: 100%;
            max-height: 400px;
            overflow-y: auto;
            .sort_item {
                display: block;
                padding: 10px 15px;
                font-size: 14px;
                background-color: $white;
                color: $main_color;
                border-top: $input_border;
                transition: .25s linear;
                &:hover {
                    color: $black;
                    background-color: #fafafa;
                }
                &.under_order {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    .size-variant-js {
                        color: rgba(25, 31, 34, 0.55);
                    }
                    svg {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    .choice-details-name {
        margin-bottom: 5px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        color: $black;
    }
    .choice-details-icon {
        margin-bottom: -7px;
        cursor: pointer;
        svg {
            fill: $main_color;
            transition: .25s linear;
        }
        &:hover svg {
            fill: $blue;
        }
    }
    .about-product-block {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .availability {
            display: flex;
            align-items: center;
            .available-icon {
                width: 18px;
                height: 18px;
                margin-right: 10px;
                svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                    fill: $good_available;
                }
            }
        }
        .availability,
        .article {
            margin-bottom: 20px;
            color: $black;
            .available {
                color: $good_available;
            }
            @media (max-width: 1024px) {
                width: 100%;
            }
            @media (max-width: 768px) {
                width: auto;
            }
            @media (max-width: 576px) {
                width: 100%;
            }
        }
        @media (max-width: 1024px) {
            .availability {
                margin-bottom: 10px;
            }
            .article {
                margin-bottom: 20px;
            }
        }
        @media (max-width: 768px) {
            .availability {
                margin-bottom: 20px;
            }
            .article {
                margin-bottom: 20px;
            }
        }
        @media (max-width: 576px) {
            .availability {
                margin-bottom: 10px;
            }
            .article {
                margin-bottom: 20px;
            }
        }
    }
    .product-buy-wrap {
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media (max-width: 1024px) {
            margin-bottom: 30px;
        }
        @media (max-width: 1180px) {
            .add-to-cart,
            .in_cart {
                width: 100%;
                margin-bottom: 25px;
                @media (max-width: 768px) {
                    width: calc(40% - 12.5px);
                    margin-bottom: 0;
                }
                @media (max-width: 576px) {
                    width: 100%;
                    margin-bottom: 25px;
                }
            }
            .quick-buy-btn {
                width: calc(80% - 12.5px);
                @media (max-width: 768px) {
                    width: calc(40% - 12.5px);
                }
                @media (max-width: 576px) {
                    width: calc(80% - 12.5px);
                }
            }
        }
    }
    .ather-color-block {
        margin-bottom: 20px;
        .ather-color-name {
            margin-bottom: 10px;
            color: $black;
        }
        .ather-color-wrap {
            margin: -15px 0 0 -15px;
            display: flex;
            flex-wrap: wrap;
        }
        .ather-color-item {
            padding: 15px 0 0 15px;
        }
        .ather-color-img {
            width: 93px;
            border: 1px solid $input_border;
        }
    }
    .details-links-block {
        margin-bottom: 20px;
        @media (max-width: 1024px) {
            display: none;
        }
        .details-link {
            position: relative;
            padding: 20px 10px 20px 25px;
            text-transform: uppercase;
            border-top: $input_border;
            cursor: pointer;
            transition: .25s linear;
            &:hover {
                color: $blue;
            }
            &:last-child {
                border-bottom: $input_border;
            }
            &::before {
                position: absolute;
                content: "";
                left: 0;
                top: 48%;
                width: 10px;
                height: 10px;
                border-left: 2px solid $main_color;
                border-bottom: 2px solid $main_color;
                transform: rotate(45deg) translateY(-50%);
            }
        }
    }
    .unit-nav {
        margin-top: 10px;
        margin-bottom: 60px;
        @media (max-width: 1024px) {
            margin-top: 0;
            margin-bottom: 35px;
        }
        @media (max-width: 768px) {
            display: none;
        }
    }
    .product-details-wrap {
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .product-details-block-info {
        padding: 245px 0;
        width: calc(66.66% - 10px);
        position: relative;
        z-index: 1;
        @media (max-width: 1024px) {
            padding: 130px 0;
            width: 100%;
        }
        @media (max-width: 768px) {
            background-color: $white;
        }
        @media (max-width: 576px) {
            padding: 35px 0;
        }
        &::before {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 2000px;
            background-color: $disable_color;
            z-index: -1;
            @media (max-width: 1024px) {
                right: -15px;
            }
            @media (max-width: 768px) {
                display: none;
            }
        }
    }
    .product-details-img {
        width: calc(33.33% - 10px);
        @media (max-width: 1024px) {
            display: none;
        }
        .scroll-top-js {
            cursor: pointer;
        }
    }
    .product-details-accordeon {
        position: relative;
        display: flex;
        flex-direction: column;
        @media (max-width: 768px) {
            flex-direction: column;
            min-height: auto;
            padding: 0;
        }
        .product-details-accordeon-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // @media (max-width: 1024px) {
            //     align-items: flex-start;
            //     margin-right: 10px;
            // }
            @media (max-width: 768px) {
                margin-right: 0;
                flex-direction: column;
            }
            &:last-child .product-descriptions__name {
                border-bottom: $input_border;
                // @media (max-width: 1024px) {
                //     border-bottom: none;
                // }
                @media (max-width: 768px) {
                    border-bottom: $input_border;
                }
            }
        }
        .product-descriptions__name {
            width: calc(25% - 10px);
            position: relative;
            padding: 20px 15px 20px 0;
            border-top: $input_border;
            text-transform: uppercase;
            cursor: pointer;
            z-index: 1;
            transition: .25s linear;
            @media (max-width: 1100px) {
                font-size: 15px;
            }
            @media (max-width: 1024px) {
                padding: 15px 15px 15px 0;
            }
            // @media (max-width: 1024px) {
            //     width: 100%;
            //     padding: 20px 10px 15px;
            //     font-size: 14px;
            //     border-top: none;
            // }
            @media (max-width: 768px) {
                width: 100%;
                padding: 15px 0;
                font-size: 16px;
                border-top: $input_border;
            }
            &:hover {
                color: $blue;
            }
            &::before {
                // @media (max-width: 1024px) {
                //     border-left: none;
                //     border-bottom: none;
                // }
                @media (max-width: 768px) {
                    position: absolute;
                    content: "";
                    right: 0;
                    top: 43%;
                    width: 10px;
                    height: 10px;
                    border-left: 2px solid $main_color;
                    border-bottom: 2px solid $main_color;
                    transform: rotate(-45deg);
                    transition: .2s linear;
                }
            }
            &.active {
                color: $main_color;
                // @media (max-width: 1024px) {
                //     color: $blue;
                // }
                @media (max-width: 768px) {
                    color: $main_color;
                }
                // &::after {
                //     @media (max-width: 1024px) {
                //         content: "";
                //         border-left: none;
                //         position: absolute;
                //         left: 10px;
                //         right: 10px;
                //         top: auto;
                //         bottom: 10px;
                //         height: 0px;
                //         transform: rotate(0);
                //         border-bottom: 1px solid $hover_color;
                //     }
                //     @media (max-width: 768px) {
                //         display: none;
                //     }
                // }
                &::before {
                    position: absolute;
                    content: "";
                    right: 5px;
                    top: 43%;
                    width: 10px;
                    height: 10px;
                    border-left: 2px solid $main_color;
                    border-bottom: 2px solid $main_color;
                    transform: rotate(225deg);
                    transition: .2s linear;
                    @media (max-width: 1100px) {
                        right: -5px;
                    }
                    // @media (max-width: 1024px) {
                    //     display: none;
                    // }
                    @media (max-width: 768px) {
                        display: block;
                        width: 10px;
                        height: 10px;
                        right: 0;
                        top: 43%;
                        left: auto;
                        border-left: 2px solid $main_color;
                        border-bottom: 2px solid $main_color;
                        transform: rotate(135deg);
                    }
                }
            }
        }
        .product-descriptions__accordion-wrap {
            padding: 30px 40px;
            width: calc(75% - 10px);
            position: absolute;
            top: -130px;
            right: 0;
            bottom: -130px;
            background-color: $white;
            z-index: -1;
            @media (max-width: 1024px) {
                top: -80px;
                bottom: -80px;
            }
            @media (max-width: 768px) {
                top: 0;
                bottom: 0;
            }
            .product-descriptions__accordion-wrap-inner {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
        .product-descriptions__accordion {
            padding: 30px 20px 30px 25px;
            display: none;
            width: calc(75% - 10px);
            position: absolute;
            top: -130px;
            right: 0;
            bottom: -130px;
            background-color: $white;
            @media (max-width: 1024px) {
                top: -80px;
                bottom: -80px;
            }
            // @media (max-width: 1024px) {
            //     padding-top: 60px;
            //     top: 0;
            //     bottom: 0;
            //     width: 100%;
            // }
            @media (max-width: 768px) {
                width: 100%;
                padding: 10px 0 20px 0;
                position: static;
            }
        }
        .product-descriptions__inner {
            overflow-y: auto;
            width: 100%;
            height: 100%;
            @media (max-width: 768px) {
                overflow-y: visible;
            }
            &.mCS_no_scrollbar{
                .mCustomScrollBox {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }
            }
        }
        .tab-content-link-moto-wrap {
            display: flex;
            flex-wrap: wrap;
            .tab-content-link {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                max-width: 33.33%;
                width: 100%;
                @media (max-width: 576px) {
                    max-width: 50%;
                }
                &:hover .tab-content-name {
                    color: $blue;
                }
            }
            .tab-content-img {
                margin-bottom: 5px;
                padding: 15px 15px 0;
                display: flex;
                align-items: flex-end;
            }
            .tab-content-name {
                padding: 0 10px 0;
                flex: 1 0 auto;
                display: flex;
                text-align: center;
                color: $main_color;
                transition: .25s linear;
            }
        }
        a.product-char-item{
            color: $main_color;
            transition: 0.25s linear;
            &:hover {
                color: $blue;
            }
        }
        .product-char-item {
            display: flex;
            align-items: center;
            &:not(:last-child) {
                margin-bottom: 15px;
                @media (max-width: 768px) {
                    margin-bottom: 10px;
                }
            }
            &.not-tooltip {
                justify-content: space-between;
                align-items: baseline;
                overflow: hidden;
                .product-char-item-value {
                    max-width: 50%;
                    position: relative;
                    padding-right: 10px;
                    background-color: $white;
                    z-index: 1;
                    &.only {
                        max-width: 100% !important;
                        padding-left: 23px;
                        &::first-letter {
                            text-transform: uppercase;
                        }
                        &::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 13px;
                            height: 12px;
                            background: url(/images/tick.png) center center/cover no-repeat;
                        }
                        &::before {
                            display: none;
                        }
                    }
                    &::before {
                        position: absolute;
                        content: '';
                        left: 100%;
                        width: 1000px;
                        bottom: 4px;
                        height: 1px;
                        border-bottom: $unit_block_border;
                    }
                }
                .product-char-item-description {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    max-width: 50%;
                    padding-left: 10px;
                    background-color: $white;
                    z-index: 1;
                    span {
                        text-align: right;
                    }
                }
            }
            .product-char-img {
                max-width: 45px;
                margin-right: 20px;
                svg {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}
// order page end

// share block start
.share-block {
    position: relative;
    display: flex;
    justify-content: flex-end;
    .share-toggle-btn {
        margin-left: 10px;
        width: 30px;
        height: 30px;
        &:hover svg {
            fill: $hover_color;
        }
        svg {
            fill: $share_item;
            transition: .2s linear;
        }
    }
    .share-socials-wrap {
        display: flex;
        margin: 0 -5px;
        opacity: 0;
        &.active {
            display: flex;
            opacity: 1;
            transition: .2s linear;
        }
        .share-socials-item {
            margin: 0 5px;
            width: 30px;
            height: 30px;
            .share-socials-link {
                display: block;
                height: 100%;
                &.viber:hover svg {
                    fill: $viber;
                }
                &.telegram:hover svg {
                    fill: $telegram;
                }
                &.facebook:hover svg {
                    fill: $facebook;
                }
                svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                    fill: $share_item;
                    transition: .2s linear;
                }
            }
        }
    }
}
// share block end

// wishlist start
#wishlist_block {
    width: calc(20% - 12.5px);
    position: relative;
    .wish_lists {
        display: none;
        padding: 10px;
        position: absolute;
        width: 280px;
        top: 105%;
        right: 0;
        border: 1px solid $hover_color;
        background-color: $white;
        z-index: 10;
    }
    .input-group {
        margin-bottom: 10px;
        display: flex;
    }
    .hide {
        display: none;
    }
    #new_wish_list {
        padding: 4px 10px;
        display: inline-block;
        width: 100%;
        height: 100%;
        border: 1px solid #ebebeb;
    }
    .do_new_wish_list {
        padding: 5px 30px;
        display: inline-block;
        text-align: center;
        background-color: $hover_color;
        color: $white;
        text-transform: uppercase;
        transition: .25s linear;
        &:hover {
            background: $bg_blue_btn;
            color: $dark_white;
        }
    }
    .to_this_wish_list {
        display: block;
        color: $bg_blue_btn;
        & + .to_this_wish_list {
            margin-top: 5px;
        }
    }
}
// wishlists end

// accessories-good-list-section start
.accessories-good-list-section {
    padding: 45px 0 90px;
    background-color: white;
    .accessories-good-main-photo {
        margin-bottom: 35px;
    }
    .accessories-good-subtitle {
        margin-bottom: 30px;
        text-align: center;
        font-size: 18px;
    }
    .accessories-link {
        text-decoration: underline;
        color: $blue;
    }
    .accessories-list-wrap {
        margin: -30px 0 0 -20px;
        display: flex;
        flex-wrap: wrap;
    }
    .accessories-list-holder {
        padding: 30px 0 0 20px;
        width: 25%;
        @media (max-width: 1024px) {
            width: 33.33%;
        }
        @media (max-width: 768px) {
            width: 50%;
        }
        @media (max-width: 576px) {
            width: 100%;
        }
    }
    .accessories-list-item {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .accessories-list-img {
        margin-bottom: 10px;
    }
    .accessories-list-name {
        text-align: center;
        color: $main_color;
    }
}
// accessories-good-list-section end

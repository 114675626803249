.header-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    background: transparent;
    transition: .25s linear;
    &.bg-white {
        background: $header-bg;
        border-bottom: $unit_block_border;
        .header-container {
            padding: 20px 0 20px;
        }
        .header-logo {
            top: -35px;
        }
        .menu__icon {
            display: block;
        }
    }
    .menu__icon {
        display: table;
    }
    .header-nav {
        z-index: 1
    }
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    background: transparent;
    transition: .25s linear;
    background: $header-bg;
    border-bottom: $unit_block_border;
    .header-container {
        max-width: 1414px;
        margin: 0 auto;
        padding: 20px 15px;
    }
    .header-logo {
        top: -35px;
    }
    .header-nav {
        top: 80px;
    }
    .main-header-nav-wrap {
        max-height: calc(100vh - 80px);
    }
    // .subcategories-nav-wrap {
    //     max-height: calc(100vh - 80px);
    // }
    .main-header-nav {
        padding: 50px 0 40px;
        @media (max-width: 1366px) {
            padding: 30px 0 25px;
        }
    }
    .main-cat-link-wrap {
        margin-bottom: 50px;
    }
    .main-cat-link {
        margin-bottom: 25px;
    }
    .bottom-nav-item {
        margin-bottom: 15px;
    }
}

@media (max-width: 1400px) {
    .header-main {
        .main-cat-link-wrap {
            margin-bottom: 50px;
        }
        .main-cat-link {
            margin-bottom: 25px;
        }
        .bottom-nav-item {
            margin-bottom: 15px;
        }
    }
}

@media (max-width: 1366px) {
    .header-main,
    .header,
    .header-main.bg-white {
        .main-cat-link {
            max-width: 420px;
        }
        .main-cat-link-wrap {
            margin-bottom: 20px;
        }
        .main-cat-link {
            margin-bottom: 10px;
        }
        .main-cat-link .main-cat-link-name {
            font-size: 26px;
        }
        .bottom-nav-item {
            margin-bottom: 10px;
        }
        .bottom-nav-link {
            font-size: 20px;
        }
        .subcategories-nav-close {
            margin-bottom: 30px;
        }
        .subcategories-nav-item:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

@media (max-width: 1024px) {
    .header-main,
    .header,
    .header-main.bg-white {
        background: $header-bg;
        .header-container {
            margin: 0;
            padding: 10px 15px;
        }
        .header-logo {
            top: -15px;
            left: 15px;
            width: 85px;
            z-index: 2;
        }
        .header-contact-wrap {
            margin-left: 100px;
        }
        .header-contact-item + .header-contact-item {
            margin-left: 25px;
        }
        .header-search {
            margin-left: 35px;
        }
        .cabinet {
            margin-left: 30px;
        }
        .top_cart {
            margin-left: 30px;
        }
        .burger-menu {
            margin-left: 30px;
        }
        .header-nav {
            top: 60px;
        }
        .main-header-nav-wrap {
            height: calc(100vh - 60px);
        }
        // .subcategories-nav-wrap {
        //     height: calc(100vh - 60px);
        // }
        .main-header-nav {
            padding: 30px 0 30px;
        }
        .header-nav-scroll {
            padding: 0 15px 0 40px;
        }
        .main-cat-link-wrap {
            margin-bottom: 40px;
        }
        .main-cat-link {
            // margin-bottom: 15px;
            &:hover,
            &.active {
                margin-left: -15px;
            }
        }
        // .main-cat-link .main-cat-link-name {
        //     font-size: 26px;
        // }
        .bottom-nav-item {
            margin-bottom: 10px;
        }
        // .bottom-nav-link {
        //     font-size: 22px;
        // }
        .header-contacts-list {
            padding: 10px 15px 0 40px;
        }
        .subcategories-nav-item:not(:last-child) {
            margin-bottom: 10px;
        }
        .subcategories-nav-link {
            font-size: 20px;
        }
    }
}

@media (max-width: 768px) {
    .header-main,
    .header,
    .header-main.bg-white {
        .header-container {
            justify-content: flex-end;
        }
        .header-contact-wrap {
            display: none;
        }
    }
}

@media (max-width: 576px) {
    .header-main,
    .header,
    .header-main.bg-white {
        .main-header-nav {
            padding: 15px 0 30px;
        }
        .main-cat-link-wrap {
            margin-bottom: 15px;
        }
        .main-cat-link {

            .main-cat-link-name {
                font-size: 20px;
            }
            .main-cat-link-name-icon {
                margin-bottom: -3px;
            }
        }
        .bottom-nav-link {
            font-size: 18px;
        }
        .subcategories-nav-link {
            font-size: 16px;
        }
        .mobile-header-block {
            margin-top: 15px;
            margin-bottom: 25px;
            display: flex;
            align-items: center;
        }
        .right-block {
            .language,
            .cabinet {
                display: none;
            }
        }
    }
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 0 60px;
    padding: 45px 0 20px;
    @media (max-width: 1414px) {
        margin: 0 15px;
    }
}
.header-logo {
    position: absolute;
    top: 10px;
    left: 0;
    width: 150px;
    transition: .25s linear;
}
.header-contact-wrap {
    display: flex;
    margin-left: 190px;
    @media (max-width: 768px) {

    }
}
.header-contact-item {
    padding: 0 5px;
    position: relative;
    & + .header-contact-item {
        margin-left: 30px;
    }
}
.header-contact-name-wrap {
    display: flex;
    align-items: center;
    cursor: pointer;
    &.active .header-contact-name-icon {
        transform: rotate(135deg);
        border-left: 2px solid $hover_color;
        border-bottom: 2px solid $hover_color;
    }
    &:hover .header-contact-name,
    &.active .header-contact-name{
        color: $hover_color;
    }
    &:hover .header-contact-name-icon {
        border-left: 2px solid $hover_color;
        border-bottom: 2px solid $hover_color;
    }
}
.header-contact-name {
    margin-right: 5px;
    font-weight: $robotomedium;
    transition: .25s linear;
}
.header-contact-name-icon {
    display: block;
    width: 6px;
    height: 6px;
    border-left: 2px solid $main_color;
    border-bottom: 2px solid $main_color;
    transform-origin: center;
    transform: rotate(-45deg);
    transition: .25s linear;
}
.header-contact-info {
    margin-top: 20px;
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    padding: 40px 60px 40px 40px;
    background: $header-contact-info;
    &.active {
        display: block;
    }
    .blue-btn {
        margin-top: 20px;
        white-space: nowrap;
    }
}
.header-item-name {
    margin-bottom: 15px;
    text-transform: uppercase;
    svg {
        margin-right: 5px;
    }
}
.header-contacts-item {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    font-size: 14px;
    white-space: nowrap;
}
.contacts__link {
    white-space: nowrap;
}
.social-link {
    margin-left: 10px;
}
.right-block {
    display: flex;
    align-items: center;
    z-index: 2;
    &.open-menu {
        & .header-search,
        & .cabinet,
        & .top_cart {
            svg {
                fill: $white;
                &:hover {
                    fill: $white_hover_menu;
                }
            }
        }
        & .language {
            .language-block {
                .language-item {
                    & + .language-item  {
                        &::before {
                            background-color: $white;
                        }
                    }
                    .language-link {
                        color: $white;
                        &.active,
                        &:hover {
                            color: $white_hover_menu;
                        }
                    }
                }
            }
        }
        & .menu__icon span {
            background: $white;
        }
        & .burger-menu {
            &:hover .menu__icon span {
                background: $white_hover_menu;
            }
        }
    }
    .top_cart {
        position: relative;
        .count-product-cart {
            position: absolute;
            top: -6px;
            right: -6px;
            width: 16px;
            height: 16px;
            background-color: $blue;
            border-radius: 50%;
            font-family: $robotomedium;
            font-size: 12px;
            line-height: 16px;
            color: $white;
            text-align: center;
        }
    }
}
.language {
    .language-block {
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        .language-item {
            padding: 0 5px;
            & + .language-item  {
                position: relative;
                &::before {
                    position: absolute;
                    display: inline-block;
                    content: '';
                    width: 1px;
                    height: 15px;
                    background-color: $main_color;
                    left: 0;
                    top: 5px;
                }
            }
            .language-link {
                font-family: $robotomedium;
                font-size: 18px;
                color: $main_color;
                transition: .25s linear;
                &.active,
                &:hover {
                    color: $hover_color;
                }
            }
        }
    }
}
.header-search,
.cabinet,
.top_cart {
    cursor: pointer;
    svg {
        fill: $main_color;
        transition: .25s linear;
        &:hover {
            fill: $hover_color;
        }
    }
}
.header-search {
    margin-left: 45px;
}
.cabinet {
    margin-left: 40px;
}
.top_cart {
    margin-left: 40px;
}
.burger-menu {
    margin-left: 40px;
    display: flex;
    width: 40px;
    height: 40px;
    cursor: pointer;
    &:hover .menu__icon span {
        background: $hover_color;
    }
}
.menu__icon {
    display: block;
    margin: auto;
    width: 30px;
    height: 22px;
    position: relative;
}

.menu__icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 30px;
    background: $main_color;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.menu__icon span:nth-child(1) {
    top: 0px;
}

.menu__icon span:nth-child(2), .menu__icon span:nth-child(3) {
    top: 8px;
}

.menu__icon span:nth-child(4) {
    top: 16px;
}

.menu__icon.open span:nth-child(1) {
    top: 8px;
    width: 0%;
    left: 50%;
}

.menu__icon.open span:nth-child(2) {
    transform: rotate(45deg);
}

.menu__icon.open span:nth-child(3) {
    transform: rotate(-45deg);
}

.menu__icon.open span:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%;
}
@keyframes animateright {
    from {
        left: 100%;
        opacity: 0;
    }
    to {
        left: 0;
        opacity: 1;
    }
}
.header-nav {
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: auto;
    position: fixed;
    background-color: rgba(25,31,34,.6);
    left: 100%;
    transition: .25s linear;
    &.open {
        left: 0;
    }
}
.main-header-nav-wrap {
    height: 100vh;
    position: relative;
    width: 50%;
    margin-left: auto;
    @media (max-width: 768px) {
        width: 100%;
    }
}
.header-nav-scroll {
    padding: 0 30px 0 100px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
.mobile-header-block {
    display: none;
    & .header-search,
    & .cabinet,
    & .top_cart {
        svg {
            fill: $white;
            &:hover {
                fill: $white_hover_menu;
            }
        }
    }
    & .language {
        .language-block {
            .language-item {
                & + .language-item  {
                    &::before {
                        background-color: $white;
                    }
                }
                .language-link {
                    color: $white;
                    &.active,
                    &:hover {
                        color: $white_hover_menu;
                    }
                }
            }
        }
    }
}
.main-header-nav {
    padding: 110px 0 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $bg_blue;
    @media (max-width: 1366px) {
        padding: 110px 0 30px;
    }
}
.main-cat-link-wrap {
    margin-bottom: 50px;
}
.main-cat-link {
    display: block;
    margin-bottom: 25px;
    transition: .25s linear;
    .main-cat-link-name {
        color: $white;
        font-size: 34px;
        text-transform: uppercase;
        transition: .25s linear;
        .main-cat-link-name-icon {
            display: inline-block;
            width: 12px;
            height: 12px;
            border-left: 2px solid $white;
            border-bottom: 2px solid $white;
            transform-origin: center;
            transform: rotate(45deg) translateY(-50%);
        }
    }
    &:hover,
    &.active {
        margin-left: -40px;
        cursor: pointer;
    }
    &:hover .main-cat-link-name,
    &.active .main-cat-link-name {
        color: $white_hover_menu;
    }
    &:hover .main-cat-link-name-icon,
    &.active .main-cat-link-name-icon {
        border-left: 2px solid $white_hover_menu;
        border-bottom: 2px solid $white_hover_menu;
    }
}
.header-bottom-nav {

}
.bottom-nav-item {
    margin-bottom: 20px;
}
.bottom-nav-link {
    font-size: 26px;
    text-transform: uppercase;
    color: $white;
    transition: .25s linear;
    &:hover {
        color: $white_hover_menu;
    }
}
.header-contacts-list {
    margin-top: auto;
    padding: 0 30px 0 100px;
    display: flex;
}
.contacts__item {
    margin-right: 30px;
    .contacts__link {
        display: block;
    }
}
.subcategories-nav {
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    transition: .25s linear;
    z-index: -1;
    background-color: $border_grey;
    &.active {
        right: 100%;
    }
}
.subcategories-nav-bg {
    width: 50%;
    @media (max-width: 1200px) {
        width: 30%;
    }
    @media (max-width: 1024px) {
        display: none;
    }
}
.subcategories-nav-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    width: 50%;
    background-color: $border_grey;
    z-index: 1;
    @media (max-width: 1366px) {
        width: 70%;
    }
    @media (max-width: 1024px) {
        width: 100%;
    }
}
.subcategories-nav-wrap {
    max-height: 100vh;
    width: 100%;
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .main-btn {
        color: $white;
        border: 1px solid $blue;
        background-color: $blue;
        transition: .25s linear;
        &:hover {
            color: $blue;
            background-color: transparent;
            border: 1px solid $blue;
        }
    }
}
.subcategories-nav-close {
    margin-bottom: 40px;
    padding: 5px;
    svg {
        cursor: pointer;
        fill: $main_color;
        transition: .25s linear;
    }
    &:hover svg {
        fill:  $hover_color;
    }
}
.subcategories-nav-list {
    margin-bottom: 40px;
    overflow-y: auto;
    overflow-x: hidden;
}
.subcategories-group-nav-item {
    &:not(:last-child) {
        margin-bottom: 20px;
        @media (max-width: 1366px) {
            margin-bottom: 10px;
        }
    }
    .subcategories-group-nav-title {
        padding: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        font-size: 22px;
        cursor: pointer;
        @media (max-width: 1366px) {
            font-size: 18px;
        }
        @media (max-width: 1024px) {
            font-size: 20px;
        }
        @media (max-width: 576px) {
            font-size: 16px;
        }
        .subcategories-group-nav-name {
            margin-right: 10px;
        }
        .subcategories-group-nav-icon {
            flex: 0 0 auto;
            width: 10px;
            height: 10px;
            border-left: 2px solid $main_color;
            border-bottom: 2px solid $main_color;
            transform-origin: center;
            transform: rotate(-45deg);
            transition: .25s linear;
            &.active {
                transform: rotate(135deg);
            }
        }
    }
    .subcategories-group-nav-list {
        display: none;
    }
    .subcategories-nav-item:not(:last-child) {
        margin-bottom: 5px;
    }
    .subcategories-nav-link {
        font-size: 18px;
        @media (max-width: 1366px) {
            font-size: 16px !important;
        }
        @media (max-width: 576px) {
            font-size: 14px !important;
        }
    }
}
.subcategories-nav-item:not(:last-child) {
    margin-bottom: 20px;
}
.subcategories-nav-link {
    font-size: 22px;
    text-transform: uppercase;
    color: $main_color;
    transition: .25s linear;
    @media (max-width: 1366px) {
        font-size: 18px;
    }
    &:hover {
        color: $hover_color;
    }
}

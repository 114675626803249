// ----------------main-----------------
.container {
  &--middle {
    max-width: 1180px;
  }
  &--small {
    max-width: 936px;
  }
  &--white {
    background-color: $white;
  }
}
 
.margin-minus {
  margin: 0 -15px;
  &--10 {
    margin: 0 -10px;
  }
}
 
.container-flex {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  &.nowrap {
      flex-wrap: nowrap;
  }
  &.between {
      justify-content: space-between;
  }
  &.start {
      justify-content: flex-start;
  }
  &.center {
      align-items: center;
  }
  &.column {
      flex-direction: column;
      align-items: center;
  }
}
.block-half {
  width: 50%;
  padding: 0 10px;
  margin-bottom: 20px;
}
.block-third {
  width: 33.3%;
  padding: 0 10px;
  margin-bottom: 20px;
}
.block-six {
  width: 16.6%;
  padding: 0 10px;
  margin-bottom: 20px;
}
 
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}
 
p iframe, p img {
  // width: auto !important;
  margin: 0 auto;
  max-width: 100%;
  display: inline-block;
  height: auto;
}
 
p img {
  display: block;
  max-width: 100%;
  height: auto !important;
  margin: 0 auto;
}
 
p iframe {
  min-height: 400px;
  width: 100% !important;
  margin: 0 auto;
}
 
.mini-flex {
  display: flex;
  align-items: center;
  .flex-svg {
      display: flex;
      align-items: center;
      margin-right: 20px;
      height: 20px;
      fill: $bg_blue;
      align-self: flex-start;
  }
  .flex-text {
      display: flex;
      // align-items: center;
      flex-direction: column;
      line-height: 1.3;
  }
}
 
%transition {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
 
//------------------- cats-list ---------
.accessories-holder--mini {
  height: 100%;
  .accessories {
    &-item {
      height: 100%;
      display: flex;
      flex-direction: column;
      &:hover {
        .accessories-name {
          transform: none;
        }
      }
    }
    &-img-cover {
    //   flex: 0 0 210px;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
    &-name {
      font-size: 16px;
      text-transform: none;
      color: $main_color;
      position: static;
      display: block;
      text-align: center;
      transition: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
  }
}
 
// ---------------content----------------
 
.page-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  background-size: cover;
  background-position: center;
  &.with-bg {
    .page-title,
    .crumb {
      color: $white;
    }
    + .container--full {
      background-color: #fafafa;
    }
    .crumb-li + .crumb-li .crumb::after {
          background-color: #fff;
      }
  }
  + .container--full {
    background-color: $white;
  }
  &.nav-right {
    justify-content: end;
  }
}
.with-big-bg {
  .unit-bg-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  & + .container--full {
    background: $white;
  }
}
.unit-bg-container {
  position: relative;
  padding: 5px 15px 20px;
  width: 100%;
  .page-title {
    font-size: 30px;
    line-height: 1.2;
    max-width: 675px;
    width: 100%;
    margin: 25px auto 15px;
    // margin-bottom: 15px;
    &--cat {
      margin: 25px auto;
    }
  }
}
.unit-nav {
  .breadcrumbs {
    justify-content: flex-end;
  }
  &--center {
    .breadcrumbs {
      justify-content: center;
    }
  }
}
.bg-mask {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba($main_color, 0.2);
}
.unit-pad {
  &--list {
    padding: 20px 15px 100px;
  }
  &--item {
    padding: 40px 15px 100px;
  }
  &--contacts {
    padding: 55px 15px 100px;
  }
}
.unit-item {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 450px;
  margin: 0 auto;
  @extend %transition;
  &-caption {
    font-size: 14px;
    padding: 20px 30px;
    background-color: $white;
    border: $unit_block_border;
    color: $main_color;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
  &-title {
    font-size: 16px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  &-date {
    color: $date_color;
    margin-bottom: 20px;
  }
  &-desc {
    margin-bottom: 30px;
  }
  .more-btn {
    margin-top: auto;
  }
  &:hover {
    .unit-item-title,
    .more-btn {
      color: $hover_color;
    }
  }
}
.add-section {
  margin: 60px 0;
  &-title {
    font-size: 26px;
    font-family: $robotoregular;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .news-page-gallery + .news-page-gallery {
    margin-top: 30px;
  }
  .section-item-title {
    font-weight: 400;
  }
}
.ather-product {
  padding: 60px 0 80px;
  .add-section-title {
    margin-bottom: 30px;
  }
}
 
// ------------ brands ----------------
.brand {
  &-link {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 60px 0;
    position: relative;
    max-width: 360px;
    margin: 0 auto;
    &:hover {
      .brand-title {
        // display: block;
        // position: absolute;
        // width: 80%;
        // text-align: center;
        // margin: 0 auto;
        // bottom: 20px;
        // left: 50%;
        // transform: translateX(-50%);
        // z-index: 1;
        color: $white;
        // font-size: 18px;
        // font-weight: 400;
        // text-transform: uppercase;
      }
      .brand-mask {
        opacity: 1;
        background: $bg_mask;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
  }
  &-logo {
    // max-height: 130px;
  }
  &-title {
      position: absolute;
      width: 80%;
      text-align: center;
      margin: 0 auto;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      color: $main_color;
      font-size: 18px;
      font-weight: 400;
      text-transform: uppercase;
      transition: .2s linear;
  }
  &-mask {
    opacity: 0;
    transition: .2s linear;
  }
  &-caption {
    margin-bottom: 60px;
    padding-bottom: 20px;
    height: 190px;
    overflow: hidden;
    position: relative;
    @extend %transition;
    transition: height 1s ease;
    &.js_open {
      height: auto;
    }
  }
  &-more-desc {
    max-height: 95%;
    overflow: hidden;
    transition: height 1s ease;
  }
  &-more-btn {
    position: absolute;
    bottom: 0;
    right: 20%;
    &.js_open {
      transform: rotate(180deg);
    }
    svg {
      width: 18px;
      height: 10px;
    }
  }
}
.logo-img {
  max-width: 410px;
  width: 100%;
  margin-bottom: 50px;
}
 
 
// -------------- contacts -------------------
.title {
  &--medium {
    font-size: 20px;
    font-family: $robotomedium;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 25px;
  }
}
.contacts {
    &-item {
      margin: 20px 0;
      line-height: 1.2;
    }
    &__link {
        color: $main_color;
        &:hover {
            color: $blue;
        }
    }
    &__inline {
        display: flex;
        margin-top: 15px;
        .contacts__item + .contacts__item {
            margin-left: 15px;
        }
        .contacts__item--socials {
          margin-right: 0;
        }
    }
    &__feedback {
        margin: 40px 0;
    }
    &__btn {
        &--send {
            display: none;
        }
    }
    &__form {
        display: none;
        margin-top: 25px;
    }
}
 
// ------------------------------ errors pages
.error {
    &-title {
        font-size: 562px;
        font-weight: 500;
        font-family: $robotomedium;
        color: $hover_color;
    }
    &-message {
        margin-bottom: 55px;
        font-size: 28px;
        font-weight: 500;
        font-family: $robotomedium;
        max-width: 578px;
        text-align: center;
    }
}
 
// ------------------------------ add-styles
.no-mg-bottom {
  margin-bottom: 0;
}
.no-mg-top {
  margin-top: 0;
}
.center-block {
  text-align: center;
}
.unit-desc {
  &--cat {
    padding: 90px 0;
  }
  p {
    margin: 20px 0;
  }
  h2 {
    font-size: 18px;
    text-transform: uppercase;
    font-family: $robotomedium;
    font-weight: 500;
  }
}
 
@media (max-width: 1200px) {
  .block-six {
    width: 20%;
  }
}
 
@media (max-width: 992px) {
  .block-half {
    width: 100%;
  }
  .block-third {
    width: 50%;
  }
  .block-six {
    width: 25%;
  }
  .news_gallery .block-third {
    width: 33.3%;
  }
  .page-title {
    font-size: 26px;
  }
  .unit-bg-container {
    padding: 5px 15px 20px;
    .page-title--cat {
      margin: 0 auto 20px;
    }
    // .breadcrumbs {
    //   .crumb-li:last-child {
    //     display: none;
    //   }
    // }
  }
  .add-section {
    margin: 30px 0;
  }
  .unit-pad {
    &--list {
      padding: 20px 15px 50px;
    }
    &--item {
      padding: 20px 15px 50px;
    }
    &--contacts {
      padding: 25px 15px 50px;
    }
  }
  .unit-desc {
    &--cat {
      padding: 45px 0;
    }
    p {
      margin: 10px 0;
    }
  }
}
 
@media (max-width: 768px) {
  .block-third {
    width: 100%;
  }
  .news_gallery .block-third {
    width: 50%;
  }
  .block-six {
    width: 33.3%;
  }
  .page-title {
    font-size: 20px;
  }
  .add-section-title {
    font-size: 20px;
  }
}
 
@media (max-width: 568px) {
  .block-six {
    width: 50%;
  }
  .news_gallery .block-third {
    width: 100%;
  }
}
 
@media (max-width: 425px) {
  .block-six {
    width: 100%;
    .accessories-holder {
      padding: 0;
      max-width: 193px;
      margin: 0 auto;
    }
  }
}
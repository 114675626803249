.tooltip:hover {
    cursor: pointer;
}
.tooltipster-sidetip .tooltipster-box {
    background: $bg_blue;
    border: 2px solid $bg_blue;
    border-radius: 4px;
}
.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
    border-top-color: $bg_blue;
}
.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
    border-top-color: $bg_blue;
}
.tooltipster-content {
    padding: 10px !important;
    background-color: $bg_blue;
    color: $white;
    font-size: 14px;
    border: $bg_blue;
}
